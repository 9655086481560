export default class Account {


    constructor(){

    }

    firstName = "";
    lastName = "";
    email = "";
    phone = "";
    password = "";
    confirmPassword = "";
    businessName = "";
   
    
    street = "";
    village = "";
    city = "";
    province = "";
    region = "";   
    zip = "";

    legalForm = ""; 
    sSNCompany = "";
    vat = "";
    pec = "";
    uniqueCode = "";
    
    attorneyFirstName = "";
    attorneyLastName = "";
    attorneyEmail = "";
    
    attorneyMunicipality = "";
    attorneyCity = "";
    attorneyProvince = "";
    attorneyRegion = "";
    attorneyZip = "";
    attorneySSN = "";
    attorneyPhone = "";
    attorneyIDCardType;
    attorneyIDCardExpiryDate;   
    attorneyIDCardNumber;
    attorneyIDCardIssuingAuthority;
    privacyAndConditionsAccepted = false;
    sponsor = "";
   
}