<template>
  <v-app>
    <v-snackbar
      :value="alert.active"
      dismissible
      :color="alert.type"
      timeout="-1"
    >
      {{ alert.message }}
    </v-snackbar>
    <!--<v-snackbar
      :value="download.active"
      color=""
      timeout="-1"
    >
      <v-progress-linear v-model="download.progress" />
      {{ download.progress }}%
    </v-snackbar>-->
    <router-view />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters({ alert: "alert/alert", download: "ui/downloadStatus" }),
  },
  watch: {
    $route (to, from) {
      // clear alert on location change
      this.$store.dispatch("alert/clear");
    },
    alert: {
      handler (val) {
        if (val.active) {
          setTimeout((_) => {
            this.$store.dispatch("alert/clear");
          }, 30000);
        }
      },
      deep: true,
    },
  },
};
</script>
