<template>
    <v-card>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loading"
            @click:row="setCurrentRow"
            @dblclick:row="rowClick"
        >
            <template v-slot:top>
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Pratiche inviate</v-toolbar-title>
                    <v-spacer />
                    <v-text-field 
                        v-model="search"
                        append-icon="fa-search"
                        label="Cerca"
                        outlined
                        single-line
                        hide-details
                    />
                </v-toolbar>
            </template>
            <template v-slot:item.service="{ item }">
                <template v-if="item.serviceType == '1'">
                    Asseverazione di conformità ai requisiti tecnici del d.m. 236 del 1989
                </template>
                <template v-else-if="item.serviceType == '2'">
                    Asseverazione di congruità di spesa
                </template>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small :loading="loading" @click="downloadModule(item.id)"> fas fa-download </v-icon>
               
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import { getModules, downloadModuleDocs } from "@/api/module.api";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                search: "",
                loading: false,
                headers: [
                    {text: "Numero modulo", value: "id", width: 150},
                    {text: "Data invio", value: "receivedDate"},
                    {text: "Cliente", value: "client"},
                    {text: "Servizio", value: "service"},
                    {text: "Stato pratica", value: "status.name"},
                    {text: "Azioni", value: "actions", sortable: false}
                ],
                items: []
            }
        },
        computed: {
            ...mapGetters({
                credits: "authentication/credits",
                creditsCongruitaSpesa: "authentication/creditsCongruitaSpesa",
            })
        },
        mounted() {
            this.$store.dispatch("authentication/getCredit");
            this.$store.dispatch("authentication/getCreditCongruitaSpesa");
            this.updateGrid();
        },
        methods: {
            updateGrid() {
                this.loading = true;
                //prendo i moduli con stato RECEIVED perchè sono quelli inviati dall'utente e ricevuti da Ambrosi
                getModules("RECEIVED")
                .then(
                    res => { this.items = res.data }
                )
                .catch(
                    e => { console.log(e) }
                )
                .finally(
                    f => { this.loading = false }
                )
            },
            downloadModule(id) {
                this.loading = true
                downloadModuleDocs(id).then((response) => {
                    let fileName = response.headers["content-disposition"].split(
                    "filename="
                    )[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    var file = new Blob([response.data]);
                    var fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName.split('"').join("");
                    a.click();
                }).catch(_ => {
                    this.$store.dispatch('alert/error', "Errore Download Pratiche");
                }).finally(_ => {
                    this.loading = false
                });
            },
           
            setCurrentRow (element) {
                this.currentRow = element
            },
            rowClick () {
                this.downloadModule(this.currentRow.id);
            },
        }
    }
</script>