<template>
  <div>
    <v-card-title class="font-weight-light headline pa-0 mb-5">
      Reimposta Password
    </v-card-title>
    <v-card-subtitle class="font-weight-light pa-0 mb-10">
      Inserisci la tua nuova password
    </v-card-subtitle>
    <v-text-field
      outlined
      label="Password"
      type="password"
      required
      :error-messages="passwordErrors"
      @input="$v.password.$touch()"
      @blur="$v.password.$touch()"
      v-model="password"
    />
    <v-text-field
      outlined
      label="Ripeti Password"
      type="password"
      required
      :error-messages="confirmPasswordErrors"
      @input="$v.confirmPassword.$touch()"
      @blur="$v.confirmPassword.$touch()"
      v-model="confirmPassword"
    />
    <v-row
      no-gutters
      justify="end"
    >
      <v-col cols="auto">
        <v-btn
          rounded
          color="secondary"
          class="pa-5"
          text
          @click="()=>{$router.push('/').catch(err=>{})}"
        >
          Indietro
        </v-btn>
        <v-btn
          rounded
          color="primary"
          class="ml-3 pa-5"
          :loading="loading"
          @click="handleSubmit"
        >
          Recupera
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    password: { required, maxLength: minLength(6) },
    confirmPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  data () {
    return {
      password: "",
      confirmPassword: "",
      loading: false,
    };
  },
  computed: {
    passwordErrors () {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Campo obbligatorio!!");
      return errors;
    },
    confirmPasswordErrors () {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push("Le password devono coincidere!!");
      return errors;
    },
  },
  methods: {
    handleSubmit (e) {
      this.loading = true;
      this.$store
        .dispatch("authentication/resetPassword", {
          token: this.$route.params.token,
          password: this.password,
          confirmPassword: this.confirmPassword,
        })
        .then(res => {
          this.$store.dispatch('alert/success', res.data.message)
          setTimeout(() => this.$router.push('/login'), 3000)
        }).catch(error => {
          this.$store.dispatch('alert/error', error.response && error.response.data.message)
        }).finally(() => {
          this.loading = false
        });
    },
  },
};
</script>
<style>
.background-auth {
  background-image: url("@/assets/bg.jpg");
  background-size: cover;
  max-width: unset !important;
  max-height: unset !important;
  width: 100vw;
  height: 100vh;
}
.v-btn__content {
  letter-spacing: 0px !important;
}
</style>