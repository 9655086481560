<template>

    <v-file-input
      outlined
      chips
      :rules="rules"
      :ref="label"
       v-model="file"
      :error-messages="errorMessages"
      loading="10%"
      :label="label"
      @click:clear="deleteFile"
      @change="clearField"
    >  
      
      <template v-slot:append-outer>
   
        <v-progress-circular
          v-if="loading&&!completed"
          :rotate="10"
          :size="50"
          :width="10"
          :value="progress"
          style="margin-top:-15px"
          :color="error?'error':'primary'"
        >
          <v-icon
            v-if="error"
            color="error"
            x-large
          >
            fa fa-times
          </v-icon>
          <span v-else>{{ progress }}</span>
        </v-progress-circular>
        <v-icon
          v-if="completed"
          color="success"
          x-large
        >
          fa fa-check
        </v-icon>
      </template>
      
    </v-file-input>
  </template>
  <script>
  import {uploadFile, uploadFileToAzure, uploadFileToAzureAsseveratore, deleteFile} from "@/api/file.api"

  export default{
      name: 'FileUpload',
      props: {
          value: {
              type: String,
              default:null
          },
          label: {
              type: String,
              required: true
          },
          fileName: {
              type: String,
              required: true
          },
          rules: {
              type: Array,
              default:()=>([])
          },
          errorMessages: {
              type: Array,
              default:()=>([])
          },
          vars: {
            type: Object,
            required: true
          },
          present: {
            type: Boolean,
            default: false
          },
         
         
  
      },
      data() {
          return {
              file:null,
              loading:false,
              progress:0,
              completed:false,
              error:false,
              fileGuid:""
          }
      },
      mounted () {
        this.fileGuid=this.createUUID()
      },
      created(){
        if(this.present){
          this.file =  new File([""], `${this.fileName}`, {type: "text/plain"})
        }

      },
      methods: {
        uploadItem () {
          this.loading = true
          let formData = new FormData();
          if (this.file){
            const ext = this.file.name.split('.').pop()
            var fileName = `${this.fileName}.${ext}`
            
            const dir = this.$attrs.directory
            if(typeof dir != 'undefined'){
              formData.append("dir", dir);
            }else{
                fileName = `${this.fileGuid}${this.fileName}.${ext}`
            }
            formData.append("file", this.file, fileName);
            formData.append("id", this.fileGuid);
            
            if (this.vars.view == 'administrationEdit'){
              formData.append("moduleId", this.vars.module.id);
              // se è un amministratore che sta caricando un file
              // devo caricarlo direttamente in azure
              if(this.vars.isDocAsseveratore){
                uploadFileToAzureAsseveratore(formData, this.onUploadProgress).then(c => {
                  this.$emit('input', this.fileGuid);
                  this.completed = true;
                }).catch(e => {
                  this.error = true;
                })

              }else{
                uploadFileToAzure(formData, this.onUploadProgress).then(c => {
                  this.$emit('input', this.fileGuid);
                  this.completed = true;
                }).catch(e => {
                  this.error = true;
                })

              }
              

            }else{
              // se è un utente normale che sta caricando un file
              // lo carico in locale
              uploadFile(formData,this.onUploadProgress).then(c=>{
                this.$emit('input', this.fileGuid)
                this.completed=true
              }).catch(e=>{
                this.error=true
              })
            }
          }
        },
        createUUID() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        },
        onUploadProgress(progressEvent){
          const total = parseFloat(progressEvent.total)
          const current = progressEvent.loaded
          this.progress = Math.floor(current / total * 100)
        
        },
        clearField(){
              this.loading=false
              this.progress=0
              this.completed=false
              this.uploadItem()
        },
        deleteFile(){
          if (this.vars.view == 'moduleStructure'){
            const ext = this.file.name.split('.').pop()
            const fileName = this.fileName; 
            const moduleId = this.vars.module.id;
            const accountId = this.vars.module.accountId
            //sono all'interno della creazione/bozza di un modulo
            //prendo il file e lo cancello
            
            deleteFile(accountId, moduleId, fileName, ext)
            .then(r=>{

            }).catch(e=>{

            });
                       
            this.$emit('input', null)
          }
        }
      }
  }
  </script>