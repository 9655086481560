import Vue from 'vue'
import App from './App.vue'
import { store } from './store'
import router from './router'
import vuetify from './plugins/vuetify'
//import axios from 'axios'


/*const app = createApp(App)

app.config.globalProperties.$router = router
app.config.globalProperties.$store = store
//app.config.globalProperties.$axios = axios

app.use(router)
app.use(store)
app.mount('#app')*/

//Vue.prototype.$http = axios

Vue.config.productionTip = true

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

//createApp(App).mount('#app')
