<template>
    <div>
        <v-stepper class="remove-shadow" vertical v-model="stepperPosition">
            <v-stepper-step
                editable
                edit-icon="fa-check"
                :complete="stepperPosition > 0"
                :rules="[validateFirstStep]"
                step="0"
            >
                <p class="ma-0">Quale servizio intendi richiederci?</p>
            </v-stepper-step>
            <br>
            <v-stepper-content step="0">
                <v-row>
                    <v-col cols="6">
                        <v-select 
                            outlined
                            label="Seleziona la tipologia di cliente"
                            :error-messages="clientTypeErrors"
                            :items="clientTypes" 
                            v-model="module.isCompany"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-select 
                            outlined
                            label="Seleziona il servizio"
                            :error-messages="serviceTypeErrors"
                            :items="serviceTypes" 
                            v-model="module.serviceType"
                            @change="updatedService"
                        />
                    </v-col>
                    <v-col cols="12" v-if="module.serviceType==1">
                        <v-autocomplete
                            outlined
                            label="Pratica collegata a cliente già inserito"
                            item-value="value"
                            item-text="text"
                            v-model="module.moduleConnectedId"
                            :items="modulesConnectable" 
                            @change="manageModuleConnectedChange"
                        />
                        
                        
                    </v-col>


                    <v-col cols="6" v-if="module.serviceType==1">
                        <v-select 
                            outlined
                            label="Seleziona la tipologia di asseverazione"
                            :error-messages="asseverazioneTypeErrors"
                            :items="asseverazioneTypes" 
                            v-model="module.asseverazioneType"
                        />
                    </v-col>
                    <v-col cols="6" v-if="module.serviceType==1">
                        <v-select 
                            outlined
                            label="Modalità riscossione bonus"
                            :error-messages="collectionTypeErrors"
                            :items="collectionTypes" 
                            v-model="module.collectionType"
                        />
                    </v-col>
                    <v-col
                        v-if="module.serviceType==2 && user.role != 'SuperAdmin' && user.role != 'Admin'"
                        cols="12"
                    >
                        <v-alert
                        dense
                        outlined
                        type="error"
                        >
                            Attenzione, per usufruire di questo servizio è necessario che il documento per asseveratore sia stato prodotto utilizzando 
                            il programma FatturaInfissi e il modulo aggiuntivo di ottimizzazione delle spese
                        </v-alert>
                    </v-col>
                    <v-col cols="6" v-if="module.serviceType==2">
                        <v-select 
                            outlined
                            label="Seleziona il servizio di asseverazione della spesa"
                            :error-messages="asseverazioneCongruitaTypeErrors"
                            :items="asseverazioneCongruitaTypes" 
                            v-model="module.asseverazioneCongruitaType"
                        />
                    </v-col>
                    <v-col
                        v-if="module.serviceType==2 && user.role != 'SuperAdmin' && user.role != 'Admin'"
                        cols="12"
                    >
                        <v-alert
                        dense
                        outlined
                        type="error"
                        >
                            L’asseverazione di progetto si distingue dall’asseverazione di congruità della spesa perché nello stesso documento redatto dal tecnico asseveratore è presente sia l’asseverazione di congruità della spesa che
                            l’asseverazione del progetto di abbattimento delle barriere architettoniche. 
                            Per produrla ti sarà richiesto di caricare, oltre agli altri documenti, anche la Dichiarazione di Progetto della quale
                            potrai scaricare il modello al termine dell’inserimento dei dati richiesti.
                            L’asseverazione di progetto va richiesta solo quando espressamente richiesta dal compratore o
                            dalla piattaforma che segue il processo di sconto in fattura; in questo momento viene richiesta
                            solo da Deloitte
                        </v-alert>
                    </v-col>
                  

                    <v-col cols="12" align="end">
                        <v-btn rounded color="primary" :disabled="!isFirstStepFilled" @click="stepperPosition++">Avanti</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-step
                :editable="isFirstStepFilled"
                edit-icon="fa-check"
                :complete="stepperPosition > 1"
                :rules=[validateBeneficiaryData]
                step="1"
            >
                <p class="ma-0">Anagrafica del beneficiario</p>
            </v-stepper-step>
            <v-stepper-content step="1">
                <v-row>
                <template v-if="!module.isCompany">
                    
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field
                                label="Nome"
                                v-model="module.clientFirstName"
                                :error-messages="(!module.clientFirstName)?['Campo obbligatorio']:[]"
                                outlined
                                required
                                placeholder=" "
                            />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field
                                label="Cognome"
                                v-model="module.clientLastName"
                                :error-messages="(!module.clientLastName)?['Campo obbligatorio']:[]"
                                outlined
                                required
                                placeholder=" "
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Codice fiscale"
                                v-model="module.clientSsn"
                                required
                                :error-messages="clientSsnErrors"
                                @input="$v.module.clientSsn.$touch()"
                                @blur="$v.module.clientSsn.$touch()"
                                outlined
                                placeholder=" "
                            />
                        </v-col>
                </template>
                <template v-if="module.isCompany">
                    <v-col cols="12" xs="12" md="6">
                            <v-text-field
                                label="Nome rappresentante legale"
                                v-model="module.attorneyFirstName"
                                :error-messages="(!module.attorneyFirstName)?['Campo obbligatorio']:[]"
                                outlined
                                required
                                placeholder=" "
                            />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field
                                label="Cognome rappresentante legale"
                                v-model="module.attorneyLastName"
                                :error-messages="(!module.attorneyLastName)?['Campo obbligatorio']:[]"
                                outlined
                                required
                                placeholder=" "
                            />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field
                                label="Codice fiscale rappresentante legale"
                                v-model="module.attorneySsn"
                                :error-messages="(!module.attorneySsn)?['Campo obbligatorio']:[]"
                                outlined
                                required
                                placeholder=" "
                            />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field
                                label="Ragione Sociale azienda"
                                v-model="module.companyName"
                                :error-messages="(!module.companyName)?['Campo obbligatorio']:[]"
                                outlined
                                required
                                placeholder=" "
                            />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field
                                label="Partita Iva azienda"
                                v-model="module.vatNumber"
                                :error-messages="(!module.vatNumber)?['Campo obbligatorio']:[]"
                                outlined
                                required
                                placeholder=" "
                            />
                        </v-col>
                </template>
                <!--<template v-if= "this.module.serviceType == '2' && this.module.asseverazioneCongruitaType==2" >
                    <v-col cols="12" xs="12" md="6">
                            <v-text-field
                                label="Indirizzo dell'intervento"
                                outlined
                                placeholder=" "
                                v-model="module.operationStreet"
                                required
                                :error-messages="operationStreetErrors"
                            />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field
                                label="Paese dell'intervento"
                                outlined
                                placeholder=" "
                                v-model="module.operationVillage"
                                required
                                :error-messages="operationVillageErrors"
                            />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-autocomplete
                                outlined
                                label="Comune dell'intervento"
                                item-value="id"
                                item-text="label"
                                v-model="selectedOperationMunicipality"
                                :items="this.municipalities"
                                :error-messages="(!this.selectedOperationMunicipality)?['Campo obbligatorio']:[]"
                                @change="manageOperationMunicipality"
                            />
                        </v-col>
                    </template>-->
                </v-row>
                

                <v-col cols="12" align="end">
                    <v-btn rounded @click="stepperPosition--">
                        Indietro
                    </v-btn>
                    
                    <v-btn  v-if="this.module.serviceType == '1'" rounded color="primary" :disabled="!isSecondStepFilled" class="ml-2" @click="stepperPosition++">
                        Avanti
                    </v-btn>
                    <v-btn v-if="this.module.serviceType == '2'" rounded color="primary" :disabled="!isSecondStepFilled" class="ml-2" @click="saveDraft(false); stepperPosition++">
                        Avanti
                    </v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                v-if="this.module.serviceType == '1' || (module.serviceType == 2 && module.asseverazioneCongruitaType ==2)"
                :editable="isSecondStepFilled"
                edit-icon="fa-check"
                :complete="stepperPosition > 2"
                :rules=[validateThirdStep]
                step="2"
            >
                <p class="ma-0">Dati catastali</p>
            </v-stepper-step>
            <v-stepper-content step="2" v-if="this.module.serviceType == '1' || (module.serviceType == 2 && module.asseverazioneCongruitaType ==2)">
                <p>
                  Questi dati possono essere recuperati dalla visura catastale che avete richiesto per verificare la categoria 
                  catastale dell'intervento ai fini della determinazione dell'aliquota Iva. 
                  Nell'indirizzo completo indicare Via, comune, provincia e CAP  
                </p>
                <v-row>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Codice Comune"
                            outlined
                            placeholder=" "
                            v-model="module.codiceComune"
                            required
                            :error-messages="codiceComuneErrors"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Sezione urbana"
                            outlined
                            placeholder=" "
                            v-model="module.sezioneCatastale"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <v-text-field
                            label="Foglio"
                            outlined
                            placeholder=" "
                            v-model="module.foglio"
                            required
                            :error-messages="foglioErrors"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <v-text-field
                            label="Particella"
                            outlined
                            placeholder=" "
                            v-model="module.particella"
                            required
                            :error-messages="particellaErrors"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <v-text-field
                            label="Subalterno"
                            outlined
                            placeholder=" "
                            v-model="module.subalterno"
                            required
                            :error-messages="subalternoErrors"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Indirizzo"
                            outlined
                            placeholder=" "
                            v-model="module.street"
                            required
                            :error-messages="streetErrors"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Paese"
                            outlined
                            placeholder=" "
                            v-model="module.village"
                            required
                            :error-messages="villageErrors"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                            outlined
                            label="Comune"
                            item-value="id"
                            item-text="label"
                            v-model="selectedComune"
                            :items="this.municipalities"
                            :error-messages="(!this.selectedComune)?['Campo obbligatorio']:[]"
                            @change="manageComune"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Regione"
                            :error-messages="regionErrors"
                            :items="regions" 
                            v-model="module.region"
                        />
                    </v-col>
                </v-row>
                <v-col cols="12" align="end">
                    <v-btn rounded @click="stepperPosition--">
                        Indietro
                    </v-btn>
                    <v-btn v-if="this.module.serviceType == '1'" rounded color="primary" :disabled="!isThirdStepFilled" class="ml-2" @click="stepperPosition++">
                        Avanti
                    </v-btn>
                    <v-btn v-if="this.module.serviceType == '2'" rounded color="primary" :disabled="!isThirdStepFilled" class="ml-2" @click="saveDraft(false); stepperPosition++">
                        Avanti
                    </v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                v-if="this.module.serviceType == '1'"
                :editable="isThirdStepFilled"
                edit-icon="fa-check"
                :complete="stepperPosition > 3"
                :rules=[validateFourthStep]
                step="3"
            >
                <p class="ma-0">Intervento</p>
            </v-stepper-step>
            <v-stepper-content step="3" v-if="this.module.serviceType == '1'">
                <p>L'intervento eseguito per l'eliminazione delle barriere architettoniche riguarda i seguenti manufatti:</p>
                <v-row>
                    <v-col cols="12" xs="12" md="3">
                        <v-text-field
                            label="Porte interne"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="porteInterne"
                            required
                        />
                        <v-text-field
                            label="Porte d'ingresso principale dell'abitazione"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="porteIngressoPrincipale"
                            required
                        />
                        <v-text-field
                            label="Finestre"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="finestre"
                            required
                        />
                        <v-text-field
                            label="Porte finestre"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="porteFinestre"
                            required
                        />
                        <v-text-field
                            label="Alzanti scorrevoli"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="alzantiScorrevoli"
                            required
                        />
                        <v-text-field
                            label="Tapparelle motorizzate"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="tapparelleMotorizzate"
                            required
                        />
                        <v-text-field
                            label="Persiane motorizzate"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="persianeMotorizzate"
                            required
                        />
                        <v-text-field
                            label="Porte per garage motorizzate"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="porteGarageMotorizzate"
                            required
                        />
                        <v-text-field
                            label="Finestre motorizzate"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="finestreMotorizzate"
                            required
                        />
                        <v-text-field
                            label="Schermature solari motorizzate"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="schermatureSolariMotorizzate"
                            required
                        />
                        <v-text-field
                            label="Grate motorizzate"
                            type="number"
                            outlined
                            placeholder=" "
                            v-model="grateMotorizzate"
                            required
                        />
                        <template v-if="module.asseverazioneType == 1">
                            <v-text-field
                                label="Porta accesso zona giorno"
                                type="number"
                                outlined
                                placeholder=" "
                                v-model="portaAccessoZonaGiorno"
                                required
                            />
                            <v-text-field
                                label="Porta servizio igienico principale"
                                type="number"
                                outlined
                                placeholder=" "
                                v-model="portaServizioIgienicoPrincipale"
                                required
                            />
                        </template>
                    </v-col>
                    <v-col cols="12">
                        <v-alert
                        dense
                        outlined
                        type="info"
                        >
                            Se stai compilando la pratica per il download della documentazione preventiva, puoi saltare l’inserimento della data
                            di fine lavori. Ricorda di inserirla quando dovrai stampare i documenti per la documentazione di
                            fine lavori.
                        </v-alert>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="formattedDate"
                                    label="Data conclusione lavori"
                                    placeholder=" "
                                    :error-messages="dataConclusioneLavoriErrors"
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="module.dataConclusioneLavori"
                                
                            />
                        </v-menu>
                    </v-col>
                </v-row>
                <v-col cols="12" align="end">
                    <v-btn rounded @click="stepperPosition--">
                        Indietro
                    </v-btn>
                    <v-btn rounded color="primary" :disabled="!isFourthStepFilled" class="ml-2" @click="createElementsTable">
                        Avanti
                    </v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                v-if="this.module.serviceType == '2' && module.asseverazioneCongruitaType==2"
                :editable="false"
                edit-icon="fa-check"
                :complete="stepperPosition>3"
                :rules=[validateFourthStep]
                :step="3"
            >
                <p class="ma-0">Caricamento Documenti</p>
            </v-stepper-step>
        
            <v-stepper-content :step="3" v-if="this.module.serviceType == '2'  && module.asseverazioneCongruitaType==2">        
                
                    <v-btn 
                        rounded color="primary"
                        :loading="generateDocumentsPreLoading"
                        @click="generateDocumentsPre">
                        Scaricare i documenti per la fase preventiva
                    </v-btn>


                <template>
                    <v-col cols="12" md="12">
                        <file-upload
                            v-if="stepperPosition == 3"
                            outlined
                            required
                            chips
                            :present="(!documentoAsseveratore)?false:true"
                            :directory="docFolder"
                            :file-name="'DocumentoAsseveratore'"
                            :vars="(user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                            v-model="documentoAsseveratore"
                            :error-messages="(!documentoAsseveratore)?['Campo obbligatorio']:[]"
                            :label="'Documento per asseveratore'"           
                        />
                    </v-col>
                    <v-col cols="12" md="12">
                        <file-upload
                            outlined
                            chips
                            v-if="stepperPosition == 3"
                            :present="(!fattura)?false:true"
                            :directory="docFolder"
                            :file-name="'Fattura'"
                             v-model="fattura"
                            :label="'Fattura'"
                            :error-messages="(!fattura)?['Campo obbligatorio']:[]"
                            :vars="(user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                        />
                    </v-col>
                    <v-col cols="12" md="12">
                        <file-upload
                            outlined
                            chips
                            v-if="stepperPosition == 3"
                            :present="(!dichiarazioneDiProgetto)?false:true"
                            :directory="docFolder"
                            :file-name="'DichiarazioneDiProgetto'"
                             v-model="dichiarazioneDiProgetto"
                            :label="'Dichiarazione di progetto'"
                            :error-messages="(!dichiarazioneDiProgetto)?['Campo obbligatorio']:[]"
                            :vars="(user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                        />
                    </v-col>
                    <v-col cols="12" md="12">
                        <file-upload
                            outlined
                            chips
                            v-if="stepperPosition == 3"
                            :present="(!visuraCatastale)?false:true"
                            :directory="docFolder"
                            :file-name="'VisuraCatastale'"
                             v-model="visuraCatastale"
                            :label="'Visura catastale'"
                            :error-messages="(!visuraCatastale)?['Campo obbligatorio']:[]"
                            :vars="( user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                        />
                    </v-col>
                </template>
                
                <v-col
                    cols="12"
                    v-if="user.role != 'Admin' && user.role != 'SuperAdmin' "
                >
                    <h3 style="color: #3593BB "> Crediti richiesti per questa pratica: </h3>
                    <ul>
                        <li v-if="module.serviceType==1"> 1 credito per asseverazione di conformità ai requisiti tecnici,  <span style="color: #3593BB"> RESIDUI: {{ credits }}</span> </li>
                        <li v-if="module.serviceType==2 "> 1 credito per asseverazione di congruità della spesa, <span style="color: #3593BB"> RESIDUI: {{ creditsCongruitaSpesa }}</span></li>
                    </ul>
                </v-col>
                <v-col
                    v-if="module.serviceType==1 && credits==0 &&  user.role != 'Admin' && user.role != 'SuperAdmin'"
                    cols="12"
                >
                    <v-alert
                    dense
                    outlined
                    type="error"
                    >
                    Crediti per asseverazione di conformità ai requisiti tecnici insufficienti
                    </v-alert>
                </v-col>
                <v-col
                    v-if="module.serviceType==2 && creditsCongruitaSpesa==0 && user.role != 'Admin' && user.role != 'SuperAdmin'" 
                    cols="12"
                >
                    <v-alert
                    dense
                    outlined
                    type="error"
                    >
                    Crediti  per asseverazione di congruità della spesa insufficienti
                    </v-alert>
                </v-col>
                <v-col cols="12" align="end"  v-if="user.role == 'SuperAdmin' || user.role == 'Admin'">
                    <v-btn rounded class="ml-2" @click="stepperPosition--">
                        Indietro
                    </v-btn>
                    <v-btn rounded color="primary" class="ml-2" @click="stepperPosition++">
                        Avanti
                    </v-btn>
                </v-col>
                <v-col cols="12" align="end" v-else>
                    <v-btn rounded color="primary" class="ml-2" @click="saveDraft(true)">
                        Salva Bozza
                    </v-btn>
                    <v-btn rounded color="primary" :disabled="!isFifthStepFilled || (module.serviceType==2 && creditsCongruitaSpesa==0)" class="ml-2" @click="uploadModule">
                        Salva e invia
                    </v-btn>
                </v-col>
            </v-stepper-content>

            <v-stepper-step
                v-if="this.module.serviceType == '2' && module.asseverazioneCongruitaType!=2"
                :editable="false"
                edit-icon="fa-check"
                :complete="stepperPosition>2"
                :rules=[validateFourthStep]
                :step="2"
            >
                <p class="ma-0">Caricamento Documenti</p>
            </v-stepper-step>
        
            <v-stepper-content :step="2" v-if="this.module.serviceType == '2'  && module.asseverazioneCongruitaType!=2">        
                <template>
                    <v-col cols="12" md="12">
                        <file-upload
                                v-if="stepperPosition == 2"
                                outlined
                                required
                                chips
                                :present="(!documentoAsseveratore)?false:true"
                                :directory="docFolder"
                                :file-name="'DocumentoAsseveratore'"
                                :vars="(user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                                v-model="documentoAsseveratore"
                                :error-messages="(!documentoAsseveratore)?['Campo obbligatorio']:[]"
                                :label="'Documento per asseveratore'"           
                            />
                        </v-col>
                    <v-col cols="12" md="12">
                        <file-upload
                            outlined
                            chips
                            v-if="stepperPosition == 2"
                            :present="(!fattura)?false:true"
                            :directory="docFolder"
                            :file-name="'Fattura'"
                             v-model="fattura"
                            :label="'Fattura'"
                            :error-messages="(!fattura)?['Campo obbligatorio']:[]"
                            :vars="( user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                        />
                    </v-col>    
                    <v-col cols="12" md="12">
                        <file-upload
                            outlined
                            chips
                            v-if="stepperPosition == 2"
                            :present="(!visuraCatastale)?false:true"
                            :directory="docFolder"
                            :file-name="'VisuraCatastale'"
                             v-model="visuraCatastale"
                            :label="'Visura catastale'"
                            :error-messages="(!visuraCatastale)?['Campo obbligatorio']:[]"
                            :vars="( user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                        />
                    </v-col>                
                </template>
                
                <v-col
                    cols="12"
                    v-if="user.role != 'Admin' && user.role != 'SuperAdmin' "
                >
                    <h3 style="color: #3593BB "> Crediti richiesti per questa pratica: </h3>
                    <ul>
                        <li v-if="module.serviceType==1"> 1 credito per asseverazione di conformità ai requisiti tecnici,  <span style="color: #3593BB"> RESIDUI: {{ credits }}</span> </li>
                        <li v-if="module.serviceType==2 "> 1 credito per asseverazione di congruità della spesa, <span style="color: #3593BB"> RESIDUI: {{ creditsCongruitaSpesa }}</span></li>
                    </ul>
                </v-col>
                <v-col
                    v-if="module.serviceType==1 && credits==0 &&  user.role != 'Admin' && user.role != 'SuperAdmin'"
                    cols="12"
                >
                    <v-alert
                    dense
                    outlined
                    type="error"
                    >
                    Crediti per asseverazione di conformità ai requisiti tecnici insufficienti
                    </v-alert>
                </v-col>
                <v-col
                    v-if="module.serviceType==2 && creditsCongruitaSpesa==0 && user.role != 'Admin' && user.role != 'SuperAdmin'" 
                    cols="12"
                >
                    <v-alert
                    dense
                    outlined
                    type="error"
                    >
                    Crediti  per asseverazione di congruità della spesa insufficienti
                    </v-alert>
                </v-col>
                <v-col cols="12" align="end"  v-if="user.role == 'SuperAdmin' || user.role == 'Admin'">
                    <v-btn rounded class="ml-2" @click="stepperPosition--">
                        Indietro
                    </v-btn>
                    <v-btn rounded color="primary" class="ml-2" @click="stepperPosition++">
                        Avanti
                    </v-btn>
                </v-col>
                <v-col cols="12" align="end" v-else>
                    <v-btn rounded color="primary" class="ml-2" @click="saveDraft(true)">
                        Salva Bozza
                    </v-btn>
                    <v-btn rounded color="primary" :disabled="!isFifthStepFilled || (module.serviceType==2 && creditsCongruitaSpesa==0)" class="ml-2" @click="uploadModule">
                        Salva e invia
                    </v-btn>
                </v-col>
            </v-stepper-content>



            <v-stepper-step
                v-if="this.module.serviceType == '1'"
                :editable="false"
                edit-icon="fa-check"
                :complete="stepperPosition > 4"
                :rules=[validateFifthStep]
                step="4"
            >
                <p class="ma-0">Caricamento Documenti</p>
            </v-stepper-step>
            <v-stepper-content step="4" v-if="this.module.serviceType == '1'">
                <template v-if="this.module.serviceType == '1'">
                    <v-btn 
                        rounded color="primary"
                        :disabled="!user.isFirstPackageAcquired"
                        :loading="generateDocumentsPreLoading"
                        @click="generateDocumentsPre">
                        Scaricare i documenti per la fase preventiva
                    </v-btn>
                    <v-btn 
                        rounded color="primary"
                        :disabled="!user.isFirstPackageAcquired || !module.dataConclusioneLavori"
                        :loading="generateDocumentsPostLoading"
                        @click="generateDocumentsPost">
                        Scaricare i documenti per la fase di fine lavori
                    </v-btn>
                    <v-col cols="12" md="12" v-if="!module.dataConclusioneLavori">
                        <v-alert
                            dense
                            outlined
                            v-if="!module.dataConclusioneLavori"
                            type="error" 
                        >
                            Per scaricare la documentazione di fine lavori popolare il campo "Data fine lavori"
                        </v-alert>
                    </v-col>
                    <v-col cols="12" md="12" v-if="!user.isFirstPackageAcquired">
                        <v-alert
                            dense
                            outlined
                            v-if="!user.isFirstPackageAcquired"
                            type="error" 
                        >
                            Per scaricare la documentazione è necessario effettuare il primo acquisto obbligatorio del Pacchetto BASE – per l’acquisto andare nella sezione “Acquista servizio”
                        </v-alert>
                    </v-col>
                    <v-col cols="12" md="12">
                        <file-upload  v-if="stepperPosition == 4"
                            outlined
                            required
                            chips
                            :present="(!docs.attoNotorioFornitore)?false:true"
                            :directory="docFolder"
                            :file-name="'AttoNotorioFornitore'"
                            :vars="( user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                            v-model="docs.attoNotorioFornitore"
                            :error-messages="(!docs.attoNotorioFornitore)?['Campo obbligatorio']:[]"
                            :label="'Atto notorio del fornitore'"           
                        />
                    </v-col>
                    <v-col cols="12" md="12">
                        <file-upload
                            v-if="stepperPosition == 4 && ((porteInterne+porteIngressoPrincipale+finestre+porteFinestre+portaServizioIgienicoPrincipale+portaAccessoZonaGiorno)>0) "
                            outlined
                            chips
                            :directory="docFolder"
                            :file-name="'DichiarazionePrestazioneTecnicaPressione'"
                            v-model="docs.dichiarazionePrestazioneTecnicaPressione"
                            :present="(!docs.dichiarazionePrestazioneTecnicaPressione)?false:true"
                            :label="(module.asseverazioneType ===1)?'Dichiarazione Sforzo ante':'Dichiarazione Pressione inferiore 8 kg'"
                            :error-messages="(!docs.dichiarazionePrestazioneTecnicaPressione)?['Campo obbligatorio']:[]"
                            :vars="( user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                        />
                    </v-col>

                    <v-col cols="12" md="12" v-if="module.asseverazioneType == 1" >
                        <file-upload  v-if="stepperPosition == 4"
                            outlined
                            required
                            chips
                            :present="(!docs.dichiarazioneProgettista)?false:true"
                            :directory="docFolder"
                            :file-name="'DichiarazioneProgettista'"
                            :vars="( user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                            v-model="docs.dichiarazioneProgettista"
                            :error-messages="(!docs.dichiarazioneProgettista)?['Campo obbligatorio']:[]"
                            :label="'Dichiarazione del progettista'"           
                        />
                    </v-col>
                    <v-col cols="12" md="12">
                        <file-upload
                            outlined
                            chips
                            v-if="stepperPosition == 4"
                            :present="(!fattura)?false:true"
                            :directory="docFolder"
                            :file-name="'Fattura'"
                             v-model="fattura"
                            :label="'Fattura'"
                            :error-messages="(!fattura)?['Campo obbligatorio']:[]"
                            :vars="( user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                        />
                    </v-col>
                    <v-col cols="12" md="12">
                        <file-upload
                            outlined
                            chips
                            v-if="stepperPosition == 4"
                            :present="(!visuraCatastale)?false:true"
                            :directory="docFolder"
                            :file-name="'VisuraCatastale'"
                             v-model="visuraCatastale"
                            :label="'Visura catastale'"
                            :error-messages="(!visuraCatastale)?['Campo obbligatorio']:[]"
                            :vars="( user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                        />
                    </v-col>
                </template>

             
                <p>Per ognuno degli elementi indicati al punto 3 dovrai caricare foto che dimostrano
                    che le barriere architettoniche sono state eliminate. Ti ricordo che nelle foto il
                    metro deve essere ben visibile, che la luce netta di passaggio si misura considerando l'ingombro dell'anta,
                    che l'altezza della maniglia si misura dal piano di calpestio e che l'altezza della soglia delle
                    portefinestre deve essere inferiore ai 25mm nel punto più alto.
                    Nella colonna del riferimento descrittivo inserisci una brevissima descrizione che ti aiuti ad
                    identificare quel manufatto nel caso in cui l'asseveratore ti chieda più informazioni. 
                    Es. finestra bagno piccolo, porta cucina, portafinestra giardino grande, etc...
                </p>

                <template v-if="stepperPosition == 4">
                    <div v-for="item in module.serramenti" :key="item.id"  >
                        <p class="text-primary"><b>{{ item.name }}</b></p>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-text-field
                                        label="ID"
                                        outlined
                                        placeholder=" "
                                        v-model="item.idRiferimentoSerramento"
                                        required
                                        disabled
                                    />
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-text-field
                                    label="Riferimento descrittivo"
                                    outlined
                                    :max-length="150"
                                    placeholder=" "
                                    :error-messages="(!item.riferimentoDescrittivo)?['Campo obbligatorio']:[]"
                                    v-model="item.riferimentoDescrittivo"
                                    required
                                />
                            </v-col>
                            <v-col cols="12" md="12" v-for="document in item.documents" :key="document.key">
                                
                                <v-alert
                                    dense
                                    outlined
                                    type="info"
                                    v-if="document.label=='Foto altezza meccanismo di sblocco anta secondaria (asta a leva)'"
                                >
                                    Foto da inserire solo nel caso in cui la luce netta di passaggio dell'anta principale sia inferiore a <span v-if="item.type=='porteIngressoPrincipale'">80 cm </span><span v-else>75 cm</span>
                                 </v-alert>
                            <file-upload
                                    outlined
                                    chips
                                    :directory="docFolder"
                                    :file-name="item.idRiferimentoSerramento+'-'+document.type"
                                    :present="(!document.path)?false:true"
                                    :vars="(user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                                    v-model="document.path"
                                    :label="document.label"
                                    :error-messages="(!document.path&& document.isMandatory)?['Campo obbligatorio']:[]"
                                    
                                />
                            </v-col>
                        </v-row>
                    </div>
                </template>
                <v-col
                    cols="12"
                    v-if="user.role == 'Admin'"
                >
                    <h3 style="color: #3593BB "> Crediti richiesti per questa pratica: </h3>
                    <ul>
                        <li v-if="module.serviceType==1"> 1 credito per asseverazione di conformità ai requisiti tecnici,  <span style="color: #3593BB"> RESIDUI: {{ credits }}</span> </li>
                        <li v-if="module.serviceType==2 "> 1 credito per asseverazione di congruità della spesa, <span style="color: #3593BB"> RESIDUI: {{ creditsCongruitaSpesa }}</span></li>
                    </ul>
                </v-col>
                <v-col
                    v-if="module.serviceType==1 && credits==0 && user.role != 'Admin' && user.role != 'SuperAdmin'"
                    cols="12"
                >
                    <v-alert
                    dense
                    outlined
                    type="error"
                    >
                    Crediti per asseverazione di conformità ai requisiti tecnici insufficienti
                    </v-alert>
                </v-col>
                <v-col
                    v-if="module.serviceType==2 && creditsCongruitaSpesa==0 && user.role != 'Admin' && user.role != 'SuperAdmin'"
                    cols="12"
                >
                    <v-alert
                    dense
                    outlined
                    type="error"
                    >
                    Crediti  per asseverazione di congruità della spesa insufficienti
                    </v-alert>
                </v-col>
                <v-col cols="12" align="end"  v-if="user.role == 'SuperAdmin' || user.role == 'Admin'">
                    <v-btn rounded class="ml-2" @click="stepperPosition--">
                        Indietro
                    </v-btn>
                    <v-btn rounded color="primary" class="ml-2" @click="stepperPosition++">
                        Avanti
                    </v-btn>
                </v-col>
                <v-col cols="12" align="end" v-else>
                    <v-btn rounded color="primary" class="ml-2" @click="saveDraft(true)">
                        Salva Bozza
                    </v-btn>
                    <v-btn rounded color="primary" :disabled="!isFifthStepFilled || (module.serviceType==1 && credits==0) || (module.serviceType==2 && creditsCongruitaSpesa==0)" class="ml-2" @click="uploadModule">
                        Salva e invia
                    </v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                v-if="user.role == 'SuperAdmin' || user.role == 'Admin'"
                :editable="isThirdStepFilled"
                edit-icon="fa-check"
                :complete="stepperPosition > stepAmministrazione"
                :rules=[validateFourthStep]
                :step= "stepAmministrazione"
            >
                <p class="ma-0">Amministrazione pratica</p>
            </v-stepper-step>
            <v-stepper-content :step="stepAmministrazione" v-if="user.role == 'SuperAdmin' || user.role == 'Admin'">
                <v-btn rounded color="primary"  class="ml-2" @click="downloadModuleDocuments" :loading="downloadModuleDocumentsLoading">
                    Download documenti pratica
                </v-btn>
                <div v-if=" module.serviceType == 2 && (module.asseverazioneCongruitaType === 0 || module.asseverazioneCongruitaType === 1)">

                </div>
                <v-btn v-else rounded color="primary" class="ml-2" @click="downloadDocumentoAsseveratore" :loading="downloadDocumentoAsseveratoreLoading">
                    Download documento asseveratore
                </v-btn>
                <br><br>
                <v-select 
                    outlined
                    :items="statuses"
                    item-disabled="disable"
                    v-model="module.status.code"
                    @change="onStatusChange(module)"
                    label = "Stato pratica"
                />
                <v-col
                    cols="12"
                    xs="12"
                    md="12"
                >
                    <file-upload
                        v-if="stepperPosition == stepAmministrazione"
                        outlined
                        chips
                        :directory="docFolder"
                        :present="(!asseverazioneFirmata)?false:true"
                        :file-name="'Asseverazione firmata'"
                        :vars="{view: 'administrationEdit', module: module, isDocAsseveratore:true}"
                        v-model="asseverazioneFirmata"
                        :label="'Documento asseveratore'"                        
                    />
                </v-col>
                <v-col cols="12" align="end">
                    <v-btn rounded @click="stepperPosition--">
                        Indietro
                    </v-btn>
                    <v-btn rounded color="primary" :disabled="!isFourthStepFilled" class="ml-2" @click="uploadModule">
                        Salva
                    </v-btn>
                </v-col>
            </v-stepper-content>
        </v-stepper>
        <v-dialog v-model="rejectDialog.visible" max-width="600">
            <v-card>
                <v-card-title class="primary white--text headline mb-3"> Rifiuto Pratica </v-card-title>
                <v-card-subtitle>
                    <br>
                    Sei sicuro di voler rifiutare la pratica? <br>
                    Questa azione riporterà la pratica in uno stati di bozza e ricaricherà il credito all'utente.
                </v-card-subtitle>
                <v-card-text>
                    <v-textarea label="Motivo rifiuto" v-model="rejectDialog.rejectReason" outlined />
                    <v-file-input 
                        outlined
                        chips
                        required
                        ref="allegatoRifiuto"
                        v-model="rejectDialog.attachedFile"
                        label="Allegato"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="cancelRejection(false)" :disabled="rejectDialog.loading"> Annulla </v-btn>
                    <v-btn @click="sendRejection()" :loading="rejectDialog.loading" :disabled="rejectDialog.loading" color="error"> Rifiuta </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { validationMixin } from "vuelidate";
    import { required, minLength, maxLength } from "vuelidate/lib/validators";
    import { updateModuleStatus, rejectModule } from "@/api/admin.api";
    import { getAllByUser} from "@/api/payments.api";
    import { uploadModule, generateDocsPreRequest, generateDocsPostRequest, getModule, downloadModuleDocs, downloadDocumentoAsseveratore, getModulesCongruitaConnectable} from "@/api/module.api.js"
    import { checkLocalFile, checkAzureFile } from "@/api/file.api";
    import DatePicker from "@/components/datePicker";
    import FileUpload from "@/components/fileUpload"
    import Module from '../../../models/Module';
    import Regions from '../../../models/Regions'
    
    export default {
        mixins: [validationMixin],
        components: { FileUpload, DatePicker },
        props: {
            editMode: Boolean,
            id: {
                type: String,
                default: ""
            }
        },
        validations: {
            module: {
                serviceType: { required },
                asseverazioneType: { required },
                clientFirstName: { required },
                clientLastName: { required },
                clientSsn: { required, minLength: minLength(16), maxLength: maxLength(16) },

                //attorneyFirstName: { required },
                //attorneyLastName: { required },
                //attorneySsn: { required },
                //companyName: { required},
                //atNumber: { required },

                codiceComune: { required },
                //sezioneCatastale: { required },
                foglio: { required },
                particella: { required },
                subalterno: { required },
                street: { required },
                village: { required },
                city: { required },
                province: { required },
                zip: { required },
                region: { required }
            }
        },
        data() {
            return {
                regions: Regions,
                stepperPosition: 0,
                downloadModuleDocumentsLoading: false,
                downloadDocumentoAsseveratoreLoading: false,
                module: new Module(),
                oldStatus: "",
                docs: {
                    dichiarazionePrestazioneTecnicaPressione: null,
                    attoNotorioFornitore: null,
                    dichiarazioneProgettista: null,
                },
                rejectDialog: {
                    visible: false,
                    loading: false,
                    moduleId: null,
                    module: null,
                    rejectReason: "",
                    attachedFile: null

                },
                documentoAsseveratore:null,
                fattura: null,
                visuraCatastale: null,
                dichiarazioneDiProgetto:null,
                porteInterne: 0,
                porteIngressoPrincipale: 0,
                finestre: 0,
                porteFinestre: 0,
                alzantiScorrevoli: 0,
                tapparelleMotorizzate: 0,
                persianeMotorizzate: 0,
                porteGarageMotorizzate: 0,
                finestreMotorizzate: 0,
                schermatureSolariMotorizzate: 0,
                grateMotorizzate: 0,
                portaServizioIgienicoPrincipale: 0,
                portaAccessoZonaGiorno: 0,
                stepAmministrazione: 5,
                selectedComune: null,
                selectedOperationMunicipality:null,
                menu: false,
                dataConclusioneLavori: null,
                elementi: {},
                serramenti: {},
                generateDocumentsPreLoading:false,
                generateDocumentsPostLoading:false,               
                asseverazioneFirmata: null,
                documentoAsseveratorePath: "",
                serviceTypes:
                [
                    {
                        text: "Asseverazione di abbattimento barriere architettoniche",
                        value: "1" 
                    },
                    {
                        text: "Asseverazione di congruità della spesa/progetto",
                        value: "2"
                    }
                ],
                asseverazioneTypes:
                [
                    {
                        text: "Asseverazione abbattimento barriere in conformità al d.m. 236/89",
                        value: 0 
                    },
                    {
                        text: "Asseverazione abbattimento barriere in conformità ai requisiti regionali",
                        value: 1
                    },
                    {
                        text: "Asseverazione abbattimento barriere in conformità alle indicazioni Deloitte/Xbonus",
                        value: 2
                    }
                ],
                asseverazioneCongruitaTypes:
                [
                    {
                        text: "Asseverazione spesa 50% ecobonus/bonus casa",
                        value: 0 
                    },
                    {
                        text: "Asseverazione spesa 75%",
                        value: 1
                    },
                    {
                        text: "Asseverazione di progetto per Deloitte/Xbonus",
                        value: 2
                    }
                ],
                collectionTypes:
                [
                    {
                        text: "A questa fornitura viene applicato lo sconto in fattura ",
                        value: 0 
                    },
                    {
                        text: "Il cliente porta in detrazione in autonomia la spesa",
                        value: 1
                    }
                ],
                clientTypes:
                [
                    {
                        text: "Azienda",
                        value: true
                    },
                    {
                        text: "Privato",
                        value: false
                    }
                ],
                modulesConnectable:[],
            }
        },
        created() {
            this.$store.dispatch("municipalities/getAllMunicipalities");
            getAllByUser(this.user.id).then((res)=>{
                if(res.data.length>0){
                    this.user.isFirstPackageAcquired = true;
                }else{
                    this.user.isFirstPackageAcquired = false;
                }
            }).catch(err=>{

            });

        },
        mounted () {
            if (this.id) {
                getModule(this.id)
                    .then(res => {
                        this.module = res.data
                        this.updatedService()
                        this.selectedComune = this.module.zip+'_'+this.module.city+'_'+this.module.province;
                        this.selectedOperationMunicipality = this.module.operationZip+'_'+this.module.operationCity+'_'+this.module.operationProvince;
                        
                        this.oldStatus = this.module.status.code;
                        //gestisco i serramenti
                        this.module.serramenti.forEach(serramento => {
                            this[serramento.type]++
                        })
     

                        if(this.user.role == "Admin" || this.user.role == "SuperAdmin"){
                                //documenti obbligatori per asseverazione di conformità
                                checkAzureFile(this.module.accountId, this.module.id, "DichiarazionePrestazioneTecnicaPressione")
                                .then(r => {
                                    if (r.data){
                                        this.docs.dichiarazionePrestazioneTecnicaPressione = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })

                                checkAzureFile(this.module.accountId, this.module.id, "DichiarazioneProgettista")
                                .then(r => {
                                    if (r.data){
                                        this.docs.dichiarazioneProgettista = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })


                                checkAzureFile(this.module.accountId, this.module.id, "AttoNotorioFornitore")
                                .then(r => {
                                    if (r.data){
                                        this.docs.attoNotorioFornitore = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })

                                //documenti obbligatori per asseverazione di congruità di spesa 

                                checkAzureFile(this.module.accountId, this.module.id, "Fattura")
                                .then(r => {
                                    if (r.data){
                                        this.fattura = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })

                                checkAzureFile(this.module.accountId, this.module.id, "VisuraCatastale")
                                .then(r => {
                                    if (r.data){
                                        this.visuraCatastale = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })

                                checkAzureFile(this.module.accountId, this.module.id, "DichiarazioneDiProgetto")
                                .then(r => {
                                    if (r.data){
                                        this.dichiarazioneDiProgetto = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })

                                checkAzureFile(this.module.accountId, this.module.id,"DocumentoAsseveratore")
                                .then(r => {
                                    if (r.data){
                                        this.documentoAsseveratore = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })
                                checkAzureFile(this.module.accountId, this.module.id, "AsseverazioneFirmata")
                                .then(r => {
                                    if (r.data){
                                        this.asseverazioneFirmata = 'trovato';
                                    }
                                })
                        }else{
                            if(this.module.serviceType=='1'){
                                for(const k in this.docs){
                                    checkLocalFile(this.module.accountId, this.module.id, k)
                                    .then(r => {
                                        if (r.data){
                                            this.docs[k] = 'trovato';
                                        }
                                    })
                                    .catch(a => {
                                        console.log('ERRORE');
                                        console.log(a);
                                    })
                                }

                                checkLocalFile(this.module.accountId, this.module.id,"Fattura")
                                .then(r => {
                                    if (r.data){
                                        this.fattura = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })

                                checkLocalFile(this.module.accountId, this.module.id, "VisuraCatastale")
                                .then(r => {
                                    if (r.data){
                                        this.visuraCatastale = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })
                            }else if (this.module.serviceType=='2'){
                                checkLocalFile(this.module.accountId, this.module.id,"Fattura")
                                .then(r => {
                                    if (r.data){
                                        this.fattura = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })

                                checkLocalFile(this.module.accountId, this.module.id, "VisuraCatastale")
                                .then(r => {
                                    if (r.data){
                                        this.visuraCatastale = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })

                                checkLocalFile(this.module.accountId, this.module.id,"DocumentoAsseveratore")
                                .then(r => {
                                    if (r.data){
                                        this.documentoAsseveratore = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })

                                checkLocalFile(this.module.accountId, this.module.id,"DichiarazioneDiProgetto")
                                .then(r => {
                                    if (r.data){
                                        this.dichiarazioneDiProgetto = 'trovato';
                                    }
                                })
                                .catch(a => {
                                    console.log('ERRORE');
                                    console.log(a);
                                })
                                
                            }
                        }        
                        
                        getModulesCongruitaConnectable(this.module.accountId,this.module.id).then(res=>{
                
                            this.modulesConnectable.push({
                                text: "Nessuna pratica collegata",
                                value: ""
                            })

                            var vetTemp = []; 

                            res.data.forEach(m=>{
                                var textValue = ""; 
                                if(m.isCompany){
                                    textValue= m.companyName;
                                }else{
                                    textValue=m.clientFirstName+" "+m.clientLastName;
                                }

                                textValue+=" - "+ m.clientSsn +" - "+m.id
                                vetTemp.push({
                                    text: textValue,
                                    value: m.id
                                });
                              
                            });
                            vetTemp.sort(this.compare);
                            vetTemp.forEach(v=>{
                                this.modulesConnectable.push(v);
                            });
                            
                        });
                    })
            }else{
                getModulesCongruitaConnectable(this.user.id, 0).then(res=>{
                
                    
                    this.modulesConnectable.push({
                                text: "Nessuna pratica collegata",
                                value: ""
                            })

                    var vetTemp = []; 
                    res.data.forEach(m=>{
                    var textValue = ""; 
                        if(m.isCompany){
                            textValue= m.companyName;
                        }else{
                            textValue=m.clientFirstName+" "+m.clientLastName;
                        }

                        textValue+=" - "+ m.clientSsn +" - "+m.id
                        vetTemp.push({
                            text: textValue,
                            value: m.id
                        });
                        
                    });
                    vetTemp.sort(this.compare);
                    vetTemp.forEach(v=>{
                        this.modulesConnectable.push(v);
                    });

                });

            }
            this.$store.dispatch("authentication/getCredit");
            this.$store.dispatch("authentication/getCreditCongruitaSpesa");

        },
        computed: {
            ...mapGetters({
                user: "authentication/user",
                municipalities: "municipalities/getAll",
                credits: "authentication/credits",
                creditsCongruitaSpesa: "authentication/creditsCongruitaSpesa",
                statuses: "moduleStatuses/getAll" 
            }),
            formattedDate() {
                if(this.module.dataConclusioneLavori){
                    this.module.dataConclusioneLavori=this.module.dataConclusioneLavori.split('T')[0];
                }
                return this.module.dataConclusioneLavori
                    ? this.module.dataConclusioneLavori.split("-").reverse().join("/")
                    : "";
            },
            docFolder() {
                if(this.id){
                    return this.user.id+"/"+this.id;
                }else{
                    const id = this.module.id
                    return this.user.id+"/"+id;
                }  
            },
            serviceTypeErrors () {
                const errors = [];
                if(this.module.serviceType == null || this.module.serviceType == ''){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            asseverazioneTypeErrors () {
                const errors = [];
                if(this.module.asseverazioneType === null || this.module.asseverazioneType === ''){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            asseverazioneCongruitaTypeErrors () {
                const errors = [];
                if(this.module.asseverazioneCongruitaType === null || this.module.asseverazioneCongruitaType === ''){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },   
            collectionTypeErrors () {
                const errors = [];
                if(this.module.collectionType === null || this.module.collectionType === ''){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },   
            clientTypeErrors () {
                const errors = [];
                if(this.module.isCompany === '' ){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            clientSsnErrors () {
                const errors = [];
                //if (!this.$v.module.codiceFiscale.$dirty) return errors;
                if(this.module.clientSsn == null || this.module.clientSsn==""){
                    errors.push("Campo obbligatorio")
                }else{
                    if(!this.$v.module.clientSsn.minLength || !this.$v.module.clientSsn.maxLength){
                        errors.push("Il codice fiscale deve essere composto da 16 caratteri");     
                    }
                }
                return errors;
            }, 
            attorneySsnErrors () {
                const errors = [];
                //if (!this.$v.module.codiceFiscale.$dirty) return errors;
                if(this.module.attorneySsn == null || this.module.attorneySsn==""){
                    errors.push("Campo obbligatorio")
                }else{
                    (!this.$v.module.attorneySsn.minLength || !this.$v.module.attorneySsn.maxLength) && 
                    errors.push("Il codice fiscale deve essere composto da 16 caratteri");     
                }
                return errors;
            },  
            codiceComuneErrors () {
                const errors = [];
                if(typeof this.module =='undefined'|| this.module.codiceComune ==null || this.module.codiceComune ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            foglioErrors () {
                const errors = [];
                if(typeof this.module.foglio =='undefined'|| this.module.foglio ==null || this.module.foglio ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            subalternoErrors () {
                const errors = [];
                if(typeof this.module.subalterno =='undefined'|| this.module.subalterno ==null || this.module.subalterno ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            /*sezioneCatastaleErrors () {
                const errors = [];
                if(typeof this.module.sezioneCatastale =='undefined'|| this.module.sezioneCatastale ==null || this.module.sezioneCatastale ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },*/
            particellaErrors () {
                const errors = [];
                if(typeof this.module.particella =='undefined'|| this.module.particella ==null || this.module.particella ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            operationStreetErrors () {
                const errors = [];
                if(typeof this.module.operationStreet =='undefined'|| this.module.operationStreet ==null || this.module.operationStreet ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            operationVillageErrors () {
                const errors = [];
                if(typeof this.module.operationVillage =='undefined'|| this.module.operationVillage ==null || this.module.operationVillage ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            operationCityErrors () {
                const errors = [];
                if(this.selectedOperationMunicipality ==null){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            streetErrors () {
                const errors = [];
                if(typeof this.module.street =='undefined'|| this.module.street ==null || this.module.street ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            villageErrors () {
                const errors = [];
                if(typeof this.module.village =='undefined'|| this.module.village ==null || this.module.village ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            cityErrors () {
                const errors = [];
                if(this.selectedComune ==null){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            regionErrors () {
                const errors = [];
                if(typeof this.module.region =='undefined'|| this.module.region ==null || this.module.region ==""){
                    errors.push("Campo obbligatorio");
                }
                return errors;
            },
            dataConclusioneLavoriErrors () {
                const errors = [];
                /*if(typeof this.module.dataConclusioneLavori =='undefined'|| this.module.dataConclusioneLavori ==null || this.module.dataConclusioneLavori ==""){
                    errors.push("Campo obbligatorio");
                }*/
            return errors;
            },
            isFirstStepFilled(){

                if(this.module.serviceType == 1 && (this.module.asseverazioneType ==='' || this.module.asseverazioneType === null || this.module.collectionType ==='' || this.module.collectionType === null)){
                    return false;
                }

                if(this.module.serviceType == 2 && (this.module.asseverazioneCongruitaType ==='' || this.module.asseverazioneCongruitaType === null) ){
                    return false;
                }
                
                if(this.module.isCompany === null || this.module.isCompany === "" || this.module.serviceType === null || this.module.serviceType === ""){
                    return false;
                }
                
                return true;
                
            },
            isSecondStepFilled(){
                return this.validateBeneficiaryData();
            },
            isThirdStepFilled(){
                return this.validateThirdStep();
            },
            isFourthStepFilled(){
                return this.validateFourthStep()
            },
            isFifthStepFilled(){
                return this.validateFifthStep()
            }
            
        }, 
        methods: {
            updatedService() {
                if(this.module.serviceType == '1'){
                    this.stepAmministrazione = 5
                }else{
                    if(this.module.asseverazioneCongruitaType==2){
                        this.stepAmministrazione = 4

                    }else{
                        this.stepAmministrazione = 3    
                    }
                    
                }
            },
            compare(a,b) {
                if ( a.text < b.text ){
                    return -1;
                }
                if ( a.text > b.text ){
                    return 1;
                }
                return 0;
            },
            generateDocumentsPre () {
                this.generateDocumentsPreLoading = true;
                generateDocsPreRequest(this.module).then((response) => {
                    let fileName = response.headers["content-disposition"].split(
                    "filename="
                    )[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    // IE variant
                    window.navigator.msSaveOrOpenBlob(
                        new Blob([response.data]),
                        fileName
                    );
                    } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    let filename = "rif-cliente"
                    if (this.module.clientFirstName && this.module.clientLastName) {
                        filename += `-${this.module.clientFirstName}-${this.module.clientLastName}`
                    }
                    if (this.module.companyName) {
                        filename += `-${this.module.companyName}`
                    }
                    if(this.module.id){
                        filename += `-${this.module.id} - fase preventiva`
                    }
                    link.setAttribute("download", `${filename}.zip`);
                    document.body.appendChild(link);
                    link.click();
                    }
                }).catch(() => {
                    this.$store.dispatch('alert/error', "Errore Download Pratiche");
                }).finally(_ => {
                    this.generateDocumentsPreLoading = false
                });
            },
            generateDocumentsPost () {
                this.generateDocumentsPostLoading = true;
                generateDocsPostRequest(this.module).then((response) => {
                    let fileName = response.headers["content-disposition"].split(
                    "filename="
                    )[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    // IE variant
                    window.navigator.msSaveOrOpenBlob(
                        new Blob([response.data]),
                        fileName
                    );
                    } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    let filename = "rif-cliente"
                    if (this.module.clientFirstName && this.module.clientLastName) {
                        filename += `-${this.module.clientFirstName}-${this.module.clientLastName}`
                    }
                    if (this.module.companyName) {
                        filename += `-${this.module.companyName}`
                    }
                    if(this.module.id){
                        filename += `-${this.module.id} - fine lavori`
                    }
                    link.setAttribute("download", `${filename}.zip`);
                    document.body.appendChild(link);
                    link.click();
                    }
                }).catch(() => {
                    this.$store.dispatch('alert/error', "Errore Download Pratiche");
                }).finally(_ => {
                    this.generateDocumentsPostLoading = false
                });
            },
            downloadModuleDocuments(){
                this.downloadModuleDocumentsLoading = true;
                downloadModuleDocs(this.module.id).then((response) => {
                    let fileName = response.headers["content-disposition"].split(
                    "filename="
                    )[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    var file = new Blob([response.data]);
                    var fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName.split('"').join("");
                    a.click();
                }).catch(_ => {
                    this.$store.dispatch('alert/error', "Errore Download Documenti Pratica");
                }).finally(_ => {
                    this.downloadModuleDocumentsLoading = false
                });
            },
            downloadDocumentoAsseveratore() {
                this.downloadDocumentoAsseveratoreLoading = true;

                downloadDocumentoAsseveratore(this.module.id).then(res => {
                    let fileName = res.headers["content-disposition"].split("filename=")[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    var file = new Blob([res.data]);
                    var fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName.split('"').join("");
                    a.click();
                }).catch(_ => {
                    this.$store.dispatch('alert/error', "Errore Download Documento Asseveratore");
                }).finally(_ => {
                    this.downloadDocumentoAsseveratoreLoading = false;
                })
            },
            manageComune(){ 
                this.$store.dispatch("municipalities/getMunicipalityDetails", this.selectedComune)
                    .then(
                        response => {
                        this.module.zip = response[0];
                        this.module.city = response[1];
                        this.module.province = response[2];
                        }, 
                        error => {
                        console.log(error);
                        }
                    );
            },
            manageOperationMunicipality(){ 
                this.$store.dispatch("municipalities/getMunicipalityDetails", this.selectedOperationMunicipality)
                    .then(
                        response => {
                        this.module.operationZip = response[0];
                        this.module.operationCity = response[1];
                        this.module.operationProvince = response[2];
                        }, 
                        error => {
                        console.log(error);
                        }
                    );
            },
            manageModuleConnectedChange(){
                
                //salvo tutti i dati selezionati al punto 0    
                const isCompanyTemp = this.module.isCompany;
                const serviceTypeTemp = this.module.serviceType;
                const asseverazioneTypeTemp =  this.module.asseverazioneType;
                const collectionTypeTemp = this.module.collectionType;
                const asseverazioneCongruitaTypeTemp = this.module.asseverazioneCongruitaType;
                const moduleConnectedIdTemp = this.module.moduleConnectedId;
                const idTemp = this.module.id;
                if(this.module.moduleConnectedId){
                    getModule(this.module.moduleConnectedId)
                    .then(res => {
                        this.module = res.data
                        this.module.serramenti = [];
                        if(this.module.asseverazioneCongruitaType == 1){
                            this.module.asseverazioneType = 0;
                            this.module.collectionType = 0;
                        }else if(this.module.asseverazioneCongruitaType == 2){
                            this.module.asseverazioneType = 2;
                            this.module.collectionType = 0;
                        }else{
                            this.module.asseverazioneType = asseverazioneTypeTemp;
                            this.module.collectionType = collectionTypeTemp;
                        }
                        
                        this.module.asseverazioneCongruitaType = null;
                        this.module.id = idTemp;
                        this.module.isCompany = isCompanyTemp;
                        this.module.serviceType = serviceTypeTemp;
                        this.module.asseverazioneCongruitaType = asseverazioneCongruitaTypeTemp;
                        this.module.moduleConnectedId = moduleConnectedIdTemp;
                        
                        
                        if(this.module.zip){
                            this.selectedComune = this.module.zip+'_'+this.module.city+'_'+this.module.province;
                        }else{
                            this.selectedComune = null;
                        }
                        if(this.module.operationZip){
                            this.selectedOperationMunicipality = this.module.operationZip+'_'+this.module.operationCity+'_'+this.module.operationProvince;
                        }else{
                            this.selectedOperationMunicipality = null
                        }
                        
                    });

                }else{
                    //liberare i campi

                    this.module = new Module();
                    this.module.id = idTemp;
                    this.module.isCompany = isCompanyTemp;
                    this.module.serviceType = serviceTypeTemp;
                    this.module.asseverazioneType = asseverazioneTypeTemp;
                    this.module.collectionType = collectionTypeTemp;
                    this.module.asseverazioneCongruitaType = asseverazioneCongruitaTypeTemp;
                    this.module.moduleConnectedId = "";
                    this.selectedOperationMunicipality = null;
                    this.selectedComune = null;
                }
              
            },
            createElementsTable(){
                this.elementi = [
                        {
                            type: 'porteInterne', 
                            id: "PI", 
                            desc: 'Porte interne', 
                            number: this.porteInterne, 
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'misuraLuceNetta',
                                    label: 'Foto misura luce netta',
                                    isMandatory:true
                                },
                               
                            ]
                        },
                        {
                            type: 'porteIngressoPrincipale', 
                            id: "PIP", 
                            desc: "Porte d'ingresso principale all'abitazione", 
                            number: this.porteIngressoPrincipale,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto dal lato interno altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'misuraLuceNetta',
                                    label: 'Foto misura luce netta',
                                    isMandatory:true
                                },
                                {
                                    key: 'meccanismoSbloccoAntaSecondaria',
                                    label: 'Foto altezza meccanismo di sblocco anta secondaria (asta a leva)',
                                    isMandatory:false
                                }
                            ]},
                        {
                            type: 'finestre', 
                            id: "F", 
                            desc: "Finestre", 
                            number: this.finestre,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                /*{
                                    key: 'meccanismoSbloccoAntaSecondaria',
                                    label: 'Foto altezza meccanismo di sblocco anta secondaria (asta a leva)',
                                    isMandatory:false
                                }*/
                            ]
                        },
                        {
                            type: 'porteFinestre', 
                            id: "PF", 
                            desc: "Portefinestre", 
                            number: this.porteFinestre,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto dal lato interno altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'misuraLuceNetta',
                                    label: 'Foto misura luce netta',
                                    isMandatory:true
                                },
                                {
                                    key: 'altezzaSoglia',
                                    label: 'Foto altezza soglia',
                                    isMandatory:true
                                },
                                {
                                    key: 'meccanismoSbloccoAntaSecondaria',
                                    label: 'Foto altezza meccanismo di sblocco anta secondaria (asta a leva)',
                                    isMandatory:false
                                }
                            ]
                        },
                        {
                            type: 'alzantiScorrevoli', 
                            id: "AS", 
                            desc: "Alzanti scorrevoli", 
                            number: this.alzantiScorrevoli,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'misuraLuceNetta',
                                    label: 'Foto misura luce netta',
                                    isMandatory:true
                                },
                                {
                                    key: 'altezzaSoglia',
                                    label: 'Foto altezza soglia',
                                    isMandatory:true
                                }
                            ]
                        },
                        {
                            type: 'tapparelleMotorizzate', 
                            id: "TM", 
                            desc: "Tapparelle motorizzate", 
                            number: this.tapparelleMotorizzate,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'motore',
                                    label: 'Foto motore',
                                    isMandatory:true
                                }
                            ]
                        },
                        {
                            type: 'persianeMotorizzate', 
                            id: "PM", 
                            desc: "Persiane motorizzate", 
                            number: this.persianeMotorizzate,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'motore',
                                    label: 'Foto motore',
                                    isMandatory:true
                                }
                            ]
                        },
                        {
                            type: 'porteGarageMotorizzate', 
                            id: "PGM", 
                            desc: "Porte per garage motorizzate", 
                            number: this.porteGarageMotorizzate,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'motore',
                                    label: 'Foto motore',
                                    isMandatory:true
                                }
                            ]
                        },
                        {
                            type: 'finestreMotorizzate', 
                            id: "FM", 
                            desc: "Finestre motorizzate", 
                            number: this.finestreMotorizzate,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'motore',
                                    label: 'Foto motore',
                                    isMandatory:true
                                }
                            ]
                        },
                        {
                            type: 'schermatureSolariMotorizzate', 
                            id: "SSM", 
                            desc: "Schermature solari motorizzate", 
                            number: this.schermatureSolariMotorizzate,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'motore',
                                    label: 'Foto motore',
                                    isMandatory:true
                                }
                            ]
                        },
                        {
                            type: 'grateMotorizzate', 
                            id: "GM", 
                            desc: "Grate motorizzate", 
                            number: this.grateMotorizzate,
                            documents: [
                                {
                                    key: 'altezzaManigliaComando',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'motore',
                                    label: 'Foto motore',
                                    isMandatory:true
                                }
                            ]
                        },
                        {
                            type: 'portaServizioIgienicoPrincipale',
                            id: "PSIP",
                            desc: "Porta servizio igienico principale",
                            number: this.portaServizioIgienicoPrincipale,
                            documents: [
                                {
                                    key:'altezzaManiglia',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'larghezzaManiglia',
                                    label: 'Foto larghezza maniglia',
                                    isMandatory:true
                                }
                            ]
                        },
                        {
                            type: 'portaAccessoZonaGiorno',
                            id: "PAZG",
                            desc: "Porta accesso zona giorno",
                            number: this.portaAccessoZonaGiorno,
                            documents: [
                                {
                                    key:'altezzaManiglia',
                                    label: 'Foto altezza maniglia/comando',
                                    isMandatory:true
                                },
                                {
                                    key: 'larghezzaManiglia',
                                    label: 'Foto larghezza maniglia',
                                    isMandatory:true
                                }
                            ]
                        }
                    ]
                    var serramentiToInsert = [];
                    var serramentiAlreadyInserted = [];
                    if(this.module.serramenti.length>0){
                        // ordino per id
                        this.module.serramenti.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
                        // creo un array chiave valore
                        for (var k=0; k < this.module.serramenti.length; ++k){
                            var s = this.module.serramenti[k];
                            var ref = s.idRiferimentoSerramento.split("_")[0];
                            if(!serramentiAlreadyInserted[ref]){
                                serramentiAlreadyInserted[ref]= [];
                            }
                            serramentiAlreadyInserted[ref].push(s);
                        }
                    }
                    this.elementi.forEach(element => {
                        for (var k=0; k < parseInt(element.number); ++k){
                            //cerco se ho già un serramento della stessa tipologia inserito nel modulo
                            if(serramentiAlreadyInserted[element.id]&& serramentiAlreadyInserted[element.id].length>0){
                                
                                for (var i =0; i<serramentiAlreadyInserted[element.id][0]['documents'].length; i++){
                                    element.documents.forEach(doc => {
                                        if (doc.key == serramentiAlreadyInserted[element.id][0]['documents'][i]['type']){
                                            serramentiAlreadyInserted[element.id][0]['documents'][i]['label']=doc.label;
                                        }
                                    })
                                
                                }
                                // se c'era già lo prendo
                                serramentiToInsert.push(serramentiAlreadyInserted[element.id][0])
                                // e poi lo elimino così non lo riprendo
                                serramentiAlreadyInserted[element.id].splice(0,1);
                            }else{
                                 //se non c'era lo creo nuovo
                                var documenti = []
                                element.documents.forEach(doc => {
                                    documenti.push({id: 0, name: "", label: doc.label, path: "", type: doc.key, moduleSerramentoId: 0})
                                })
                                console.log(element.type)
                                serramentiToInsert.push({idRiferimentoSerramento: element.id+'_'+(k+1), type: element.type, name: element.desc+' '+(k+1), riferimentoDescrittivo: "", path: "", documents: documenti})
                            }   
                        }
                    });
                   
                    this.module.serramenti = serramentiToInsert.slice();
                    
                    if(this.user.role != "SuperAdmin" && this.user.role != 'Admin'){
                        this.saveDraft(false)
                    }
                    this.stepperPosition++
            },
            validateFirstStep(){
                if(this.stepperPosition == 0){
                    return true
                }else{
                   return this.isFirstStepFilled;
                }  
            },
            validateBeneficiaryData(){
                if (this.stepperPosition >= 1){
                    if(this.module.isCompany){
                        if(
                            !this.module.attorneyFirstName ||
                            !this.module.attorneyLastName || 
                            !this.module.attorneySsn ||
                            !this.module.companyName ||
                            !this.module.vatNumber 
                        ){
                            return false;
                        }   
                    }else{
                        if(
                            this.module.clientFirstName == null ||
                            !this.module.clientLastName == null || 
                            !this.module.clientSsn 
                        ){
                            return false;
                        }
                    }
                }
                return true;
            },
            validateThirdStep(){
                if (this.stepperPosition >= 2){
                    if(
                        (!this.module.codiceComune ||
                        !this.module.foglio ||
                        !this.module.particella ||
                        !this.module.subalterno ||
                        !this.module.street ||
                        !this.module.village ||
                        !this.module.city ||
                        !this.module.province ||
                        !this.module.zip ||
                        !this.module.region) 
                    ){
                        return false;
                    }
                }

                if(this.module.region !== "Molise" && this.module.region !== "Lombardia"){
                    this.portaAccessoZonaGiorno = 0;
                    this.portaServizioIgienicoPrincipale = 0;
                }

                return true;
            },
            validateFourthStep(){
                if(this.module.serviceType=='2'){


                }else{
                    var sum = Number(this.porteIngressoPrincipale)+Number(this.porteInterne)+Number(this.finestre)+Number(this.porteFinestre)+Number(this.alzantiScorrevoli)+Number(this.tapparelleMotorizzate)+Number(this.persianeMotorizzate)+Number(this.porteGarageMotorizzate)+Number(this.finestreMotorizzate)+Number(this.schermatureSolariMotorizzate)+Number(this.grateMotorizzate)+Number(this.portaServizioIgienicoPrincipale)+Number(this.portaAccessoZonaGiorno);
                    if(sum === 0){
                        return false;
                    }
                }

                
  
                /*if(
                    !this.module.porteInterne ||
                    !this.module.porteIngressoPrincipale ||
                    !this.module.finestre ||
                    !this.module.porteFinestre ||
                    !this.module.alzantiScorrevoli ||
                    !this.module.tapparelleMotorizzate ||
                    !this.module.persianeMotorizzate ||
                    !this.module.porteGarageMotorizzate
                ){
                    return false;
                }*/
                return true;
            },
            validateFifthStep(){
                if(this.module.serviceType == 1){
                    if (this.stepperPosition > 3){
                        return this.checkDocs()&& this.hasEnoughCredits();
                    }else{
                        return true;
                    }
                }else if(this.module.serviceType == 2){
                    if (this.stepperPosition > 1){
                        if(this.module.asseverazioneCongruitaType == 2){
                            return this.dichiarazioneDiProgetto != null && this.documentoAsseveratore != null && this.fattura!=null && this.visuraCatastale!=null && this.hasEnoughCredits();
                        }else{
                            return this.documentoAsseveratore != null && this.fattura!=null && this.visuraCatastale!=null && this.hasEnoughCredits();
                        }
                    }else{
                        return true;
                    }
                }
                
            },
            hasEnoughCredits(){
                
                if (this.user.role == "SuperAdmin" || this.user.role == "Admin"){
                    return true;
                }

                if(this.module.serviceType ==1 && this.credits == 0){
                    return false;
                }
                if(this.module.serviceType ==2 && this.creditsCongruitaSpesa == 0){
                    return false;
                }
                return true;
            },

            checkDocs(){
                if (this.module.serviceType == 1){
                    if (
                        this.docs.attoNotorioFornitore === null ||
                        (this.module.asseverazioneType == 1 && this.docs.dichiarazioneProgettista === null) ||
                        this.fattura === null || this.visuraCatastale === null
                        ) {
                        //TODO inserire notification
                        //console.log('Documenti obbligatori mancanti');
                        return false
                    }
                    if(this.docs.dichiarazionePrestazioneTecnica === null && (porteInterne+porteIngressoPrincipale+finestre+porteFinestre+portaServizioIgienicoPrincipale+portaAccessoZonaGiorno)>0){
                        return false;
                    }
                }

                //controllo se ci sono tutti i documenti righiesti dai serramenti selezionati

                if(this.module.serviceType == 1){
                    for (const s in this.module.serramenti){
                        for(const d in this.module.serramenti[s].documents){
                            if(this.module.serramenti[s].documents[d].isMandatory){
                                if (this.module.serramenti[s].documents[d].path === null || this.module.serramenti[s].documents[d].path === ""){
                                    //console.log('Documenti di serramenti mancanti');
                                    return false
                                }
  
                            }
                        }
                    }

                }
                
                return true
            },
            saveDraft(redirect){
                if (this.user.role == "SuperAdmin" || this.user.role == "Admin")
                    return;
                
                this.module.status = {code: "DRAFT"};
                this.module.accountId = this.user.id
                if(this.module.serviceType==2 || this.module.serviceType=="2" || this.module.moduleConnectedId ==""){
                    this.module.moduleConnectedId = null;
                }

                uploadModule(this.module)
                    .then(c => {
                        if (redirect) {
                            this.$router.push('/modules/drafts').catch(e => {})
                        }else{
                            this.module = c.data

                            //gestisco i titoli di ogni serramento
                           this.elementi.forEach(element => {
                                var tmp = 0
                                this.module.serramenti.forEach(ser => {
                                    if (ser.type == element.type && !ser.name){
                                        tmp++
                                        ser.name = element.desc+' '+tmp
                                    }
                                })
                            })
                            //gestisco le label dei documenti di ogni serramento
                            this.module.serramenti.forEach(ser => {
                                ser.documents.forEach( doc => {
                                    this.elementi.forEach( element => {
                                        if (element.type == ser.type){
                                            element.documents.forEach( edoc => {
                                            if (edoc.key == doc.type){
                                                doc.label = edoc.label
                                            } 
                                        })
                                    }    
                                    })
                                })
                            })
                        };
                    })
                    .catch(c => {
                        console.log(c)
                    })
            },
            onStatusChange(module) {
                if (module && module.status && (module.status.code == "REJECTED" || module.status.code == "WAITING")) {
                    this.rejectDialog.visible = true;
                    this.rejectDialog.moduleId = module.id
                    this.rejectDialog.statusCode = module.status.code
                    return
                }
                this.loadingTable = true;

                updateModuleStatus(module).then(res => {
                    this.oldStatus = module.status.code;
                }).catch(e => {
                    this.module.status.code = this.oldStatus
                    this.$store.dispatch('alert/error', e.response.data);
                }).finally(() => {
                    this.loadingTable = false;
                })
            },
            sendRejection() {
                this.rejectDialog.loading = true
                const form = this.rejectDialog
                form.loading = true
                let formData = new FormData();
                formData.append('moduleId', form.moduleId);
                formData.append('rejectReason', form.rejectReason);
                formData.append('statusCode', form.statusCode);
                if (form.attachedFile)
                    formData.append('attachedFile', form.attachedFile);

                rejectModule(formData).then(() => {
                    this.cancelRejection(true);
                }).finally(() => {this.rejectDialog.loading = false})
                
            },
            cancelRejection(isRejected) {
                this.rejectDialog = {
                    visible: false,
                    loading: false,
                    rejectReason: "",
                    moduleId: null,
                    attachedFile: null,
                    statusCode: ""
                }
                if (!isRejected){
                    this.module.status.code = this.oldStatus
                }

            },
            uploadModule(){
              
                if(this.user.role != "SuperAdmin" && this.user.role != 'Admin'){
                    this.module.status = {code: "RECEIVED"};
                    this.module.accountId = this.user.id
                    if (!this.checkDocs()) return
                }
            
                uploadModule(this.module, this.user.role)
                    .then(c => {
                        if(this.user.role != "SuperAdmin" && this.user.role != 'Admin'){
                            this.$router.push('/modules/sended').catch(e => {})
                        }else{
                            this.$router.push('/administration/modules/received')
                        }
                    })
                    .catch(c => {
                        console.log(c)
                    })
            }
      
        }
    }
</script>