
var legalForm = [
    "Srl",
    "Srls",
    "Snc",
    "Sas",
    "Soc.Coop",
    "Ditta Individuale",
    "Spa"
  ]


export default legalForm;