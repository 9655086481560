<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="filteredPayments"
      :search="search"
      dense
      height="75vh"
      :loading="loadingTable"
      fixed-header
      :footer-props="{
        'items-per-page-options':[10, 20, 30, 40]
      }"
        >
      <template v-slot:top>
        <v-toolbar
          dark
          color="primary"
          flat
        >
          <v-toolbar-title>Pagamenti</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Cerca"
            single-line
            hide-details
            outlined
          />  
        </v-toolbar>
         <v-btn text>
          <v-btn
            @click="exportToExcel"
            :loading="loadingExport"
            :disabled="loadingTable"
            text
          >
            Esporta Excel
          </v-btn>
        </v-btn>
      </template>
    
    </v-data-table>
      
  </v-card>
</template>
<script>
import { getAll  } from "@/api/payments.api";
import { mapGetters } from "vuex";
import * as XLSX from 'xlsx';
export default {
 
  data () {
    return {
      search: "",
      headers: [
       {text: "Id", value: "id", type: "string"},
        {text: "Date", value: "date", type: "string"},
        { text: "Email cliente", value: "accountEmail", type: "string", filterActive: true },
        { text: "Rag Sociale", value: "accountBusinessName", type: "string", filterActive: true },
        { text: "P. IVA", value: "accountVat", type: "string", filterActive: true },
        { text: "CF", value: "accountSsn", type: "string", filterActive: true }, 
        { text: "Descrizione pagamento", value: "payPalReference", type: "string", filterActive: false },
        { text: "Numero crediti", value: "credits", type: "number", filterActive: false },
        
      ],
      payments: [],
      loading: false,
      loadingExport: false,
      filterModal: {
        visible: false,
        selectedFilter: {},
        value: ""
      },
      filters: [],
      loadingTable: false  
    };
  },
  mounted () {
    this.loadDataSource();
    
  },
  computed: {
    ...mapGetters({ user: "authentication/user" }),
 
    
    filteredPayments () {
      let payments = this.payments
      this.filters.forEach(filter => {
        if (typeof filter.value == "boolean") {
          payments = payments.filter(c => c[filter.field] === filter.value)
        }
        else { payments = payments.filter(c => {

          if(typeof c[filter.field] != 'undefined'){
            if(c[filter.field].includes(filter.value)){
              return c;
            }
          }

        }) }
      })
      return payments
    }
  },
  methods: {
    loadDataSource () {
      this.loadingTable = true
      getAll().then((result) => {
         this.$nextTick(()=>{
           this.payments = result.data;
         })
        this.loadingTable = false
      }).catch(err=>{
        console.log(err);
      });
    },
    exportToExcel () {
        this.loadingExport=true
        getAll().then(res=>{
        let wb = XLSX.utils.book_new()
        var mappedData = res.data.map(row => {
          let obj= {
            "Id": row.id,
            "Email cliente": row.accountEmail,
            "Rag.Soc": row.accountBusinessName,
            "P.IVA": row.accountVat,
            "CF": row.accountSsn,
            "Descrizione pagamento": row.payPalReference,
            "Numero crediti": row.credits,
            "Data": row.date,
            "Totale": row.amount 
            }
          return obj
        })
      
        let ws = XLSX.utils.json_to_sheet(mappedData);
        XLSX.utils.book_append_sheet(wb, ws, 'Utenti')
        XLSX.writeFile(wb, `Estrazione-pagamenti-${this.dateToYMD()}.xlsx`)
        this.loadingExport=false
      })
      },
    dateToYMD (date) {
      const dateObj = date ? new Date(date) : new Date();
      var d = dateObj.getDate();
      var m = dateObj.getMonth() + 1; //Month from 0 to 11
      var y = dateObj.getFullYear();
      return '' + (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
    },
    
  },
};
</script>
<style >
.text-start {
  font-size: 0.8rem !important;
}
</style>