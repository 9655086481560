<template>
  <v-col 
    v-if="arePaymentsEnabled"
    cols="12"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-card-title>
          Acquista servizio
        </v-card-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col
            
            v-for="rPackage in packages"
            :key="rPackage.id"
            cols="12"
            xs="12"
            md="4"
          >
            <v-card
              :color="rPackage.color"
              class="card-outter"
              height="100%"
              dark
            >
              <v-card-title class="headline">
                {{ rPackage.name }}
              </v-card-title>

              <v-card-text class="package-body">
                <ul class="mb-3">
                  <li>
                    {{ rPackage.numberOfCredits }} {{ (rPackage.numberOfCredits>1)?"pratiche": "pratica"}}
                  </li>
                  <li v-if="rPackage.isIncrementable">
                    € {{ priceCreditsCongruitaSpesa(rPackage)}}+iva
                  </li>
                  <li v-else> 
                    € {{ rPackage.price }}+iva
                  </li>
                  
                  <li v-if="rPackage.isIncrementable">
                    € {{ rPackage.price }}/pratica
                  </li>
                  <li v-else> 
                    € {{ pricePerCredit(rPackage) }}/pratica
                  </li>
                </ul>
                
                {{ rPackage.description }}

                <template v-if="rPackage.isIncrementable">
                  <v-text-field
                    v-model="rPackage.numberOfCredits"
                    hide-details
                    single-line
                    type="number"
                    min="1"
                    label="Numero di pratiche desiderate"
                  />

                </template>

                
              </v-card-text>
          
              <v-card-actions class="card-actions">
                <v-spacer />
                <v-btn
                  v-if="rPackage.isIncrementable"
                  rounded
                  :to="'/payments/'+rPackage.id+'?numCredits='+rPackage.numberOfCredits"
                  text
                >
                  Acquista
                </v-btn>
                
                <v-btn
                v-else
                  rounded
                  :to="'/payments/'+rPackage.id"
                  text
                >
                  Acquista
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
          
        </v-row>
    
      </v-container>
    </v-card>
  </v-col>
  <v-col
    v-else
    cols="12"
    xs="12"
    md="12"
  >
    <v-card
      dark
      color="#C21923"
    >
        <v-card-title>Attenzione</v-card-title>
        <v-card-text class="headline">
                L'acquisto dei pacchetti di crediti è disabilitato fino a martedì 22 agosto per ferie del personale.
        </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import { getPackages } from "@/api/recharge.api";
export default {
  data () {
    return {
      packages: [],
      alertDialog: false,
      arePaymentsEnabled: true
    };
  },
  mounted () {
    if (!this.user.sellerContractVerified && !localStorage.getItem("warningVendita")) {
     // this.alertDialog = true
    }
    if(this.arePaymentsEnabled){
      getPackages().then((res) => {
        this.packages = res.data;
      });
    }
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
  methods: {
    pricePerCredit (rPackage) {
      return parseFloat(rPackage.price / rPackage.numberOfCredits).toFixed(2);
    },
    priceCreditsCongruitaSpesa(rPackage){
          return parseFloat(rPackage.price * rPackage.numberOfCredits).toFixed(2);
    },
    doNotShowAgain () {
      localStorage.setItem("warningVendita", true)
      this.alertDialog = false
    }
  },
};
</script>
<style>
.package-body {
  max-width: 400px;
}
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>