<template>
  <div v-if="!registrationComplete">
    <v-card-title class="font-weight-light headline pa-0 mb-5">
      Compila i seguenti campi per completare la registrazione
    </v-card-title>
    <v-card-subtitle class="font-weight-light pa-0 mb-10">
      Al termine della procedura, verrà inviata una mail di validazione dell'account
    </v-card-subtitle>
    <v-col cols="12">
      <v-text-field
          outlined
          label="Sponsor"
          v-model="account.sponsor"
      />
    </v-col>
    <v-col cols="12">
    <v-text-field
      outlined
      label="Password"
      type="password"
      required
      :error-messages="passwordErrors"
   
      v-model="account.password"
    />
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        label="Ripeti Password"
        type="password"
        required
        :error-messages="confirmPasswordErrors"
    
        v-model="account.confirmPassword"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        label="Nome rappresentante legale"
        type="text"
        required
        :error-messages="attorneyFirstNameErrors"
    
        v-model="account.attorneyFirstName"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        label="Cognome rappresentante legale"
        type="text"
        required
        :error-messages="attorneyLastNameErrors"
    
        v-model="account.attorneyLastName"
      />
    </v-col>
    <v-col cols="12">
      <v-select
          :items="idTypes"
          outlined
          label="Documento di identificazione"
          v-model="account.attorneyIDCardType"
          :error-messages="attorneyIDCardTypeErrors"
      />
    </v-col>
    <v-col cols="12">
      <v-menu
          ref="menuExpiryDate"
          v-model="menuExpiryDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
          <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  outlined
                  :value="formattedDate"
                  label="Data scadenza Documento di identità"
                  placeholder=" "
                  :error-messages="attorneyIDCardExpiryDateErrors"
                  v-bind="attrs"
                  v-on="on"
              />
          </template>
      <v-date-picker
          ref="picker"
          v-model="account.attorneyIDCardExpiryDate"
          :min="currentDate"
      />
  </v-menu>
</v-col>
<v-col cols="12">
  <v-text-field
      label="Numero Documento di identità"
      outlined
      v-model="account.attorneyIDCardNumber"
      placeholder=" "
      :error-messages="attorneyIDCardNumberErrors"
  />
</v-col>
<v-col
cols="12"
>
  <v-text-field
      outlined
      label="Comune di rilascio Documento di identità"
      v-model="account.attorneyIDCardIssuingAuthority"
      placeholder=""
      :error-messages="attorneyIDCardIssuingAuthorityErrors"
      required
  />
</v-col>
    <v-col
        cols="12"
        xs="12"
        md="12"
    >
    <v-file-input
        outlined
        chips
        :rules="[
        (value) =>
            !value ||
            value.size < 40000000 ||
            'Dimensione massima consentita di 40MB superata!'
        ]"
        ref="ciFrontFile"
        v-model="ciFront"
        label="Documento di identità fronte"
    />
    </v-col>
    <v-col
        cols="12"
        xs="12"
        md="12"
    >
    <v-file-input
        outlined
        chips
        :rules="[
        (value) =>
            !value ||
            value.size < 40000000 ||
            'Dimensione massima consentita di 40MB superata!'
        ]"
        ref="ciRetroFile"
        v-model="ciRear"
        label="Documento di identità retro"
    />
    </v-col>






    <a href="/assets/termini_di_servizio.pdf"   target="_blank">Scarica qui i termini e le condizioni di registrazione </a>    <br>
        <v-checkbox
            :rules="[(value) =>value]"
            required
            v-model="account.privacyAndConditionsAccepted"
            label="Accetto i termini e le condizioni"
          > 
        </v-checkbox>
        <v-row
          no-gutters
          justify="end"
        >
      <v-col cols="auto">
        <v-btn
          rounded
          color="secondary"
          class="pa-5"
          text
          @click="()=>{$router.push('/login')}"
        >
          Indietro
        </v-btn>
        <v-btn
          rounded
          color="primary"
          class="ml-3 pa-5"
          :loading="loading"
          :disabled="!account.privacyAndConditionsAccepted ||  passwordErrors.length> 0 || this.confirmPasswordErrors.length> 0"
          @click="handleSubmit"
        >
          Procedi con la validazione dell'account
        </v-btn>
      </v-col>
    </v-row>

  </div>
  <div v-else>
      <v-card-title class="font-weight-light headline pa-0 mb-5">
        <v-icon
          color="success"
          class="mr-3"
        >
          fas fa-check-circle
        </v-icon>
        Registrazione completata!
      </v-card-title>
      <v-card-subtitle class="font-weight-light pa-0 mb-5">
        Controlla la tua mail per confermare la registrazione
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          class="px-0"
          text
          @click="$router.push('/login')"
        >
          Torna al login
        </v-btn>
      </v-card-actions>
      
    </div>
</template>
<script>
import Account from "../../models/Account";

import IDTypes from "../../models/IDTypes";
import { get, updateTermsApi } from '@/api/accounts.api';

export default {
 
  data () {
    return {
      account: new Account(),
      loading: false,
      registrationComplete: false,
      ciFront: null,
      ciRear: null,
      idTypes: IDTypes,
      menuExpiryDate: false
    };
  },
  created (){
      if(this.$route.params.account){
        this.account = this.$route.params.account 
        this.account.password = ""
      }
  },
  computed:{
    currentDate() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        return `${yyyy}-${mm}-${dd}`;
    },
    formattedDate() {
      if(this.account.attorneyIDCardExpiryDate){
        this.account.attorneyIDCardExpiryDate=this.account.attorneyIDCardExpiryDate.split('T')[0];
      }
      return this.account.attorneyIDCardExpiryDate
          ? this.account.attorneyIDCardExpiryDate.split("-").reverse().join("/")
          : "";
    },
    passwordErrors(){
      var errors = [];
      if(this.account.password =="" ){
        errors.push("Campo obbligatorio");
      }
      return errors;
    },
    confirmPasswordErrors(){
      var errors = [];
      if(this.account.confirmPassword =="" || this.account.confirmPassword != this.account.password){
        errors.push("le password devono coincidere");
      }
      return errors;
    },
    attorneyFirstNameErrors(){
      var errors = [];
      if(this.account.attorneyFirstName =="" ){
        errors.push("Campo obbligatorio");
      }
      return errors;
    },
    attorneyLastNameErrors(){
      var errors = [];
      if(this.account.attorneyLastName =="" ){
        errors.push("Campo obbligatorio");
      }
      return errors;
    },
    attorneyIDCardExpiryDateErrors(){
      var errors = [];
      if(this.account.attorneyIDCardExpiryDate =="" ){
        errors.push("Campo obbligatorio");
      }
      return errors;
    },
    attorneyIDCardIssuingAuthorityErrors(){
      var errors = [];
      if(this.account.attorneyIDCardIssuingAuthority =="" ){
        errors.push("Campo obbligatorio");
      }
      return errors;
    },
    attorneyIDCardNumberErrors() {
      var errors = [];
      if(this.account.attorneyIDCardNumber =="" ){
        errors.push("Campo obbligatorio");
      }
      return errors;
    },
    attorneyIDCardTypeErrors() {
      var errors = [];
      if(this.account.attorneyIDCardType =="" ){
        errors.push("Campo obbligatorio");
      }
      return errors;
    }
  },
  methods: {
    handleSubmit(){
      if(this.account.privacyAndConditionsAccepted && this.passwordErrors.length== 0 && this.confirmPasswordErrors.length== 0){
        this.loading = true;
        let formData = new FormData();
        formData.append('user', JSON.stringify(this.account))
        if (this.ciFront && this.ciFront !== undefined) {
            const ext = this.ciFront.name.split(".").pop();
            const fileName = `I.D. FRONTE ${this.account.attorneyLastName}_${this.account.attorneyFirstName}.${ext}`;
            formData.append("ciFront", this.ciFront, fileName);
        }
        if (this.ciRear && this.ciRear !== undefined) {
            const ext = this.ciRear.name.split(".").pop();
            const fileName = `I.D. RETRO ${this.account.attorneyLastName}_${this.account.attorneyFirstName}.${ext}`;
            formData.append("ciRear", this.ciRear, fileName);
        }
      updateTermsApi(formData)
      .then(res=>{
        this.registrationComplete = true;

        }).catch(err=>{
            //todo manage error
        }).finally(()=>{
          this.loading = false;
        });
      }
     
    }
  },
 
};
</script>
<style>
.background-auth {
  background-image: url("@/assets/bg.jpg");
  background-size: cover;
  max-width: unset !important;
  max-height: unset !important;
  width: 100vw;
  height: 100vh;
}
.v-btn__content {
  letter-spacing: 0px !important;
}
</style>