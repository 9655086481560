<template>
  <v-card>
    <v-toolbar
      color="primary"
      dark
    >
      <v-card-title>
        Documenti utili
      </v-card-title>
    </v-toolbar>
    <v-card-text class="subtitle-1">
      <v-row>
        <v-col v-if="user.isFirstPackageAcquired">
          <v-btn
            rounded
            color="success"
            :loading="contractLoading"
            @click="openInNewTab('/assets/Guida alle asseverazioni per le barriere architettoniche REV 6.0 gennaio 2024.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Guida alle asseverazioni per le barriere architettoniche REV 6.0 gennaio 2024
          </v-btn>
        </v-col>
      </v-row>
     
      <v-row>
        <v-col v-if="user.isFirstPackageAcquired">
          <v-btn
            rounded
            color="success"
            :loading="contractLoading"
            @click="openInNewTab('/assets/Assicurazione Arch. Cosimo Bianchi.zip')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Assicurazione Arch. Cosimo Bianchi
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="user.isFirstPackageAcquired">
          <v-btn
            rounded
            color="success"
            :loading="contractLoading"
            @click="openInNewTab('/assets/Assicurazione Arch. Alberto Conte.zip')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Assicurazione Arch. Alberto Conte
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
   
 
  </v-card>
</template>
<script>
 import { mapGetters } from "vuex";
export default {
  data () {
    return {
    
    }
  },
  computed: {
    ...mapGetters({ user: "authentication/user" }),
  },
  methods: {
    openInNewTab (href) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: href,
      }).click();
    }
  }

}
</script>