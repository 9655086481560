<template>
    <v-container
      style="height:100%;"
      class="d-flex justify-center background-auth"
    >
      <v-card
        class="align-self-center pa-5 v-card-auth"
        :width="cardWidth"
      >
        <v-row align="center">
          <v-col
            md="5"
            cols="12"
            class="d-none d-md-flex"
          >
            <v-img src="/assets/AP_COMPLETO_SCONTORNATO.png" />
          </v-col>
          <v-col
            md="7"
            cols="12"
          >
            <router-view />
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </template>
  <script>
  export default {
    computed: {
      cardWidth () {
        return window.screen.width > 800 ? "100%" : "95%";
      },
    },
  };
  </script>