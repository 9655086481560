export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null
    },
    actions: {
        success({
            commit
        }, message) {
            commit('success', message);
        },
        error({
            commit
        }, message) {
            commit('error', message);
        },
        clear({
            commit
        }) {
            commit('clear');
        }
    },
    mutations: {
        success(state, message) {
            state.active = false
            state.type = 'green';
            state.message = message;
            state.active = true
        },
        error(state, message) {
            state.active = false
            state.type = 'red';
            state.message = message;
            state.active = true
        },
        clear(state) {
            state.active = false
            state.type = null;
            state.message = null;
            state.active = false
        }
    },
    getters: {
        alert: state => {
            return state
        }
    }
}
