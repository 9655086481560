import api from './baseApi'

export function rejectModule(module) {
    return api.post(`module/rejectmodule`, module, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export function updateModuleStatus(module) {
    return api.patch(`module/updateModuleStatus`, module)
  }
  