import axios from 'axios';
import { store } from '../store'
class Service {
    constructor() {
        let service = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            timeout: 480000,
            withCredentials: true
        });
        service.interceptors.request.use((config) => {
            let user = JSON.parse(localStorage.getItem('user'));
            if (!config.headers) config.headers = {}
            if (user && user.jwtToken) {
                config.headers.Authorization = 'Bearer ' + user.jwtToken
            }
            if(!config.headers["Content-Type"])config.headers["Content-Type"] = 'application/json';
            return config;
        });
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    console.warn("Api resource not found")
                    break;
                case 401:
                    return this.refreshToken(error);
                case 400:
                    if (error.response.data && (error.response.data.title || error.response.data.message)) { console.log(error) }
            }
        }
        return Promise.reject(error)
    }

    redirectTo = (document, path) => {
        document.location = path
    }

    refreshToken = (error) => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.jwtToken) {
            user = user.jwtToken
        }
        return this.service.post('Accounts/refresh-token', user).then(resp => {
            let user = resp.data
            if (user.jwtToken) {
                localStorage.setItem('user', JSON.stringify(user));
            }
            const originalRequest = error.config;
            originalRequest._retry = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.jwtToken;
            return this.service(originalRequest);
        }).catch((error) => {
            console.error(error)
            localStorage.removeItem('user')
           // window.location = '/'
        })
    }

    get(path, params) {
        return this.service.get(path, params);
    }

    patch(path, payload) {
        return this.service.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload
        });
    }

    delete(path) {
        return this.service.delete(path);
    }

    post(path, payload, options) {
        return this.service.request({
            method: 'POST',
            url: path,
            responseType: 'json',
            data: payload,
            ...options
        });
    }

    put(path, payload, options) {
        return this.service.request({
            method: 'PUT',
            url: path,
            responseType: 'json',
            data: payload,
            ...options
        });
    }

    download(path) {
        store.dispatch('ui/beginDownload')
        return this.service.request({
            method: 'GET',
            url: path,
            responseType: 'blob',
            onDownloadProgress: progressEvent => {
                const total = parseFloat(progressEvent.total)
                const current = progressEvent.loaded

                let percentCompleted = Math.floor(current / total * 100)
                store.dispatch('ui/progressDownload', percentCompleted)
            }
        }).finally(() => {
            store.dispatch('ui/endDownload')
        });
    }

    downloadPOST(path, payload) {
        return this.service.request({
            method: 'POST',
            data: payload,
            url: path,
            responseType: 'blob'
        });
    }
}

export default new Service;
