import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import it from 'vuetify/es5/locale/it'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

const myCustomLightTheme = {
    dark: false,
    colors: {
      background: '#FFFFFF',
      surface: '#FFFFFF',
      primary: '#3593BB',
      'primary-darken-1': '#153B4B',
      secondary: '#BB5D35',
      'secondary-darken-1': '#9f5332',
      error: '#B00020',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
    }
}
export default new Vuetify({
    lang: {
      locales: { it },
      current: 'it'
    },
    icons: {
      iconfont: 'fa'
    },
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
          myCustomLightTheme,
        }
    }
});
