
export const ui = {
    namespaced: true,
    state: {
        sidebar: false,
        download:{
            active:false,
            progress:0
        }
    },
    actions: {
        toggleSidebar({ commit }) {
            commit('toggleSidebar');
        },
        beginDownload({ commit }) {
            commit('beginDownload');
        },
        progressDownload({ commit },payload) {
            commit('progressDownload',payload);
        },
        endDownload({ commit }) {
            commit('endDownload');
        },
    },
    mutations: {
        toggleSidebar(state) {
            state.sidebar = !state.sidebar
        },
        beginDownload(state) {
            state.download.active = true
        },
        progressDownload(state, payload) {
            state.download.progress = payload
        },
        endDownload(state) {
            state.download.progress=0 
            state.download.active = false
        },
    },
    getters:{
        sidebar: state => {
            return state.sidebar
          },
        downloadStatus: state => {
            return state.download
          }
    }
}