import {
    getModuleStatuses
} from '@/api/module.api'

const moduleStatusesList = JSON.parse(localStorage.getItem('moduleStatusesList'));

const initialState = moduleStatusesList ? {
    moduleStatusesList
} : {
    moduleStatusesList: {}
}

export const moduleStatuses = {
    namespaced: true,
    state: initialState,
    actions: {
        getModuleStatuses({
            commit
        }){
            return getModuleStatuses().then(res => {
                commit('setModuleStatuses', res.data)
            })
        }
    },
    mutations: {
        setModuleStatuses(state, list) {
            var tmp = [];
            for (let i = 0; i < list.length; i++) {
                var value = list[i].code;
                var text = list[i].name;
                tmp[i] = {'value': value, 'text': text};
                if (value == "DRAFT"){
                    tmp[i].disable = true;
                }
            }
            state.moduleStatusesList = tmp
            localStorage.setItem('moduleStatusesList', JSON.stringify(state.moduleStatusesList));
        }
    },
    getters: {
        getAll: state => {
            return state.moduleStatusesList
        }
    }
}