<template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
        flat
      >
        <v-toolbar-title>Utente</v-toolbar-title>
        <v-spacer />
        <v-col cols="auto">
            <v-btn text>
                <v-btn :loading="downloadIDLoading" @click="downloadUserDocuments" text>Scarica documenti utente</v-btn>
            </v-btn>
        </v-col>
      </v-toolbar>
      <v-card-text>
        <v-card
            elevation="2"
        >
        <p style="padding-left: 5px;">
            <b>Ragione sociale</b> {{account.businessName}}<br>
            <b>Indirizzo</b> {{account.street}} - {{account.city}}({{account.province}}) {{account.zip}}<br>
            <b>Partita Iva</b> {{account.vat}} - <b>Codice fiscale</b> {{account.ssnCompany}}<br>
            <b>Telefono</b> {{account.phone}}<br>
            <b>Nome operatore referente</b> {{ account.attorneyFirstName }} {{ account.attorneyLastName }}<br>
            <b>Telefono operatore referente</b> {{account.attorneyPhone}}<br>
         
            <b>Email</b> {{account.email}} - <b>Pec</b> {{account.pec}}<br>
            <b>CF legale rappresentante</b> {{account.attorneySSN}} - <b>Telefono legale rappresentante</b> {{account.attorneyPhone}}
        </p>
        </v-card>
        <v-card
            elevation="2"
        >
        <p style="padding-left: 5px;">
            <b>Numero utente</b> {{account.id}}<br>
            <b>Data registrazione</b> {{dateToYMD(account.created)}}<br>
            <b>Email verificata</b> {{account.isVerified}} 
            <v-btn
                rounded
                text
                color="primary"
                v-if="account.isVerified == 'No'"
                @click="verifyEmail"
                >
                Verifica manuale
                </v-btn>
            <br>
            <b>Crediti per asseverazioni di conformità ai requisiti tecnici  </b> 
                <v-btn
                    rounded
                    text
                    color="primary"
                    @click="showCreditsDialog()"
                >
                    <span v-if="account.credits !== undefined">
                        {{account.credits}}
                    </span>
                    <v-progress-circular
                        v-else
                        indeterminate
                        size="18"
                    />
                </v-btn>
            <br>
            <b>Crediti per asseverazione congruità della spesa</b>
            <v-btn
                rounded
                text
                color="primary"
                @click="showCreditsCongruitaSpesaDialog()"
            >
                <span v-if="account.creditsCongruitaSpesa!== undefined">
                    {{ account.creditsCongruitaSpesa }} 
                </span>
                <v-progress-circular
                    v-else
                    indeterminate
                    size="18"
                />
            </v-btn>
            <br>
          <b>Sponsor</b>
            <v-btn
                text
                rounded
                color="primary"
                @click="editSponsor()"
            >
                {{ account.sponsor || 'Aggiungi Sponsor' }}
            </v-btn>
          
        </p>
        </v-card>

      </v-card-text>
        <v-dialog
            v-model="creditsDialog.visible"
            persistent
            max-width="360"
        >
            <v-card>
            <v-card-title class="headline">
                Ricarica crediti per asseverazione di conformità
            </v-card-title>
            <v-card-text>
                Crediti attuali utente {{ account.credits }}
                
                <v-select
                    :items="packages"
                    item-value="name"
                    item-text="name"
                    label="Pacchetto"
                    @change="packageSelected"
                />

                <v-text-field
                label="Numero crediti per asseverazione di conformità"
                type="number"
                v-model="creditsDialog.creditsToRecharge"
                >
                0
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                rounded
                text
                v-show="!creditsDialog.loading"
                @click="focusOut"
                >
                Annulla
                </v-btn>
                <v-btn
                rounded
                color="primary"
                text
                :loading="creditsDialog.loading"
                @click="rechargeCredits()"
                >
                Conferma
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="creditsCongruitaSpesaDialog.visible"
            persistent
            max-width="360"
        >
            <v-card>
            <v-card-title class="headline">
                Ricarica crediti per aseeverazione di congruità della spesa
            </v-card-title>
            <v-card-text>
                Crediti attuali utente {{ account.creditsCongruitaSpesa }}
                <v-text-field
                label="Numero crediti per asseverazione di congruità della spesa"
                type="number"
                v-model="creditsCongruitaSpesaDialog.creditsToRecharge"
                >
                0
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                rounded
                text
                v-show="!creditsCongruitaSpesaDialog.loading"
                @click="focusOut"
                >
                Annulla
                </v-btn>
                <v-btn
                rounded
                color="primary"
                text
                :loading="creditsCongruitaSpesaDialog.loading"
                @click="rechargeCreditsCongruitaSpesa()"
                >
                Conferma
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
      
        <v-dialog
            v-model="addEditSponsor.visible"
            persistent
            max-width="360"
        >
            <v-card>
            <v-card-title>
                Modifica sponsor utente <br>{{ account.email }}
            </v-card-title>
            <v-card-text>
                <v-text-field
                label="Sponsor"
                v-model="sponsorToChange"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                rounded
                text
                v-show="!addEditSponsor.loading"
                @click="editSponsorAction(false)"
                >
                Annulla
                </v-btn>
                <v-btn
                rounded
                color="primary"
                text
                :loading="addEditSponsor.loading"
                @click="editSponsorAction(true)"
                >
                Conferma
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog.visible" max-width="600">
            <v-card>
                <v-card-title class="primary white--text headline mb-3"> Eliminazione account </v-card-title>
                <v-card-subtitle>
                    <br>
                    Sei sicuro di voler eliminare l'account? <br>
                
                </v-card-subtitle>
               
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="cancelDelete()" :disabled="deleteDialog.loading"> Annulla </v-btn>
                    <v-btn @click="sendDelete()" :loading="deleteDialog.loading" :disabled="deleteDialog.loading" color="error"> Conferma </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn
                v-if="account.isDeletable"
                text
                align="end"
                rounded
                color="red"
                @click="deleteAccount()"
            >
             {{ 'Elimina account' }}
        </v-btn>
    </v-card>
   
          
  </template>
  <script>
    import { mapGetters } from "vuex";
    import { get, changeSponsor, downloadUserDocs, verifyEmailManual, deleteAccount } from "@/api/accounts.api";
    import { rechargeAdmin, rechargeCongruitaAdmin, getPackages} from "@/api/recharge.api";
    
    export default {
      
        computed: {
            ...mapGetters({
                user: "authentication/user",
            }),
           
        },
        created (){
            if(this.$route.params.id){
                this.id = this.$route.params.id 
            }
        },
        mounted (){
            get(this.id).then(res=>{
                this.account = res.data;
                this.sponsorToChange = this.account.sponsor;
                this.account.isVerified = (res.data.isVerified)? "Si": "No";
             
            });

            getPackages().then((res) => {
                res.data.map((value)=>{
                    if(!value.creditsCongruitaSpesa){
                        this.packages.push(value)
                    }
                })
            })
        },
        data (){
            return {
                deleteDialog: {
                    visible: false,
                    loading: false
                   
                },
                updateUserBuyerLoading:false,
                updateOperatorLoading:false,
                updateClientTypeLoading:false,
                downloadIDLoading: false,
                sponsorToChange: "",
                packages: [],
                account: {},
                creditsDialog: {
                    visible: false,
                    activation: false,
                    loading: false,
                    creditsToRecharge: 0
                },
                creditsCongruitaSpesaDialog: {
                    visible: false,
                    activation: false,
                    loading: false,
                    creditsToRecharge: 0
                },
               
               
                addEditSponsor: {
                    visible: false,
                    loading: false,
                    user: {},
                },
                
                alreadyEdited: false
            }
        },
        methods: {
            deleteAccount(){
                this.deleteDialog.visible = true;
            },
            cancelDelete(){
                this.deleteDialog.visible = false;
            },
            sendDelete(){
                this.deleteDialog.loading = true;

                deleteAccount(this.id).then(res=>{
                    this.$router.push("/administration/users/").catch(err=>{});
                }).catch(err=>{
                    this.$store.dispatch('alert/error', 'Errore eliminazione account', {root: true})
                }).finally(()=>{
                    this.deleteDialog.loading = false;
                    this.deleteDialog.visible = false;
                })


            },
            packageSelected(el){
                this.packages.forEach(p=>{
                    if(p.name == el){
                        this.creditsDialog.creditsToRecharge = p.numberOfCredits;
                    }
                });
                
            },
            dateToYMD (date) {
                const dateObj = date ? new Date(date) : new Date();
                var d = dateObj.getDate();
                var m = dateObj.getMonth() + 1; //Month from 0 to 11
                var y = dateObj.getFullYear();
                return '' + (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
            },
            refreshAccount () {
                getAccount(this.id).then(res=>{
                    this.$nextTick(()=>{
                        this.account = res.data;
                        this.account.isVerified = (res.data.isVerified)? "Si": "No";
                    })
                });
            },
            showConfirmSellerVerification (value) {
                this.confirmSellerVerificationDialog.activation = value;
                this.confirmSellerVerificationDialog.visible = true;
            },
            showCreditsDialog () {
                this.creditsDialog.visible = true;
            },
            showCreditsCongruitaSpesaDialog () {
                this.creditsCongruitaSpesaDialog.visible = true;
            },
          
            rechargeCredits () {
               this.creditsDialog.loading = true;
               rechargeAdmin( this.account.id, parseInt(this.creditsDialog.creditsToRecharge))
                .then(() => {         
                    this.account.credits = parseInt(this.account.credits)+ parseInt(this.creditsDialog.creditsToRecharge);
                })
                .finally(() => {

                    this.creditsDialog.visible = false;
                    this.creditsDialog.loading = false;
                    this.creditsDialog.creditsToRecharge = 0;
                });
            },
            rechargeCreditsCongruitaSpesa() {
                this.creditsDialog.loading = true;
                rechargeCongruitaAdmin( this.account.id, parseInt(this.creditsCongruitaSpesaDialog.creditsToRecharge))
                .then(() => {         
                    this.account.creditsCongruitaSpesa = parseInt(this.account.creditsCongruitaSpesa)+ parseInt(this.creditsCongruitaSpesaDialog.creditsToRecharge);
                })
                .finally(() => {

                    this.creditsCongruitaSpesaDialog.visible = false;
                    this.creditsCongruitaSpesaDialog.loading = false;
                    this.creditsCongruitaSpesaDialog.creditsToRecharge = 0;
                });
            },
          
            focusOut () {
               this.creditsDialog.visible = false;
               this.creditsCongruitaSpesaDialog.visible = false;
            },
            verifyEmail () {
                verifyEmailManual(this.id).then(res=>{
                    this.account.isVerified = "Si";
                }).catch(err=>{
                    this.$store.dispatch('alert/error', 'Errore nella verifica', {root: true})
                    this.account.isVerified = "No";
                })
            },
            editSponsor () {
                this.addEditSponsor = {
                visible: true,
                loading: false,
                user: this.account
                }
            },
            editSponsorAction (action) {
               if (action) {
                    this.addEditSponsor.loading = true
                    changeSponsor(this.account.id, this.sponsorToChange)
                    .then(res=>{
                        this.account.sponsor = this.sponsorToChange;
                    })
                    .catch(err=>{
                        this.sponsorToChange = this.account.sponsor;
                        this.$store.dispatch('alert/error', 'modifica sponsor non riuscita', {root: true})
                    })
                    .finally(c => {
                        this.addEditSponsor = {
                        visible: false,
                        loading: false,
                        user: {}
                        }
                    })
                } else {
                    this.addEditSponsor = {
                        visible: false,
                        loading: false,
                        user: {}
                    }
                   
                }
            },
            downloadUserDocuments () {
                this.downloadIDLoading = true
                downloadUserDocs(this.account.id)
                    .then(response => {
                        this.registrationComplete = true;
                        let fileName = response.headers["content-disposition"].split(
                        "filename="
                        )[1];
                        fileName = fileName.substring(0, fileName.indexOf(";"));
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        var file = new Blob([response.data]);
                        var fileURL = URL.createObjectURL(file);
                        a.href = fileURL;
                        a.download = fileName.split('"').join("");
                        a.click();
                    })
                    .catch(err => {console.log(err)})
                    .finally(f => { this.downloadIDLoading = false})
            },
           
            
           
          /*  sendCredentialMail(){
                this.loadingCredentialMail = true;
                sendCredentialMail(this.account.id)
                .finally(c => {
                    this.loadingCredentialMail = false;
                })
            }*/
        }
       
    }
  </script>
  