import api from './baseApi'

export function getPackages() {
    return api.get(`Recharge`)
}

export function getPackage(id,numCredits) {
    return api.get(`Recharge/${id}?numCredits=${numCredits}`)
}

export function recharge(orderId, numCredits, isCongruita) {
    return api.get(`Recharge/captureorder/${orderId}?numCredits=${numCredits}&isCongruita=${isCongruita}`)
}

export function rechargeAdmin(accountId, numCredits) {
    return api.post(`Recharge/recharge`,{ UserId:accountId, CreditsToRecharge:numCredits})
}

export function rechargeCongruitaAdmin(accountId, numCredits) {
    return api.post(`Recharge/recharge-congruita`,{ UserId:accountId, CreditsToRecharge:numCredits})
}