<template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
        flat
      >
        <v-toolbar-title>Crea nuova pratica</v-toolbar-title>
      </v-toolbar>
      <moduleStructure />
    </v-card>
  </template>
  <script>
  import moduleStructure from './components/moduleStructure'
  export default {
    components: { moduleStructure },
  };
  </script>
  