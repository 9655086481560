export default class Module {


    constructor(){

    }
    
    id = 0;
    status = "";
    serviceType = "1";
    asseverazioneType = 0;
    collectionType = 1;
    isCompany = false;
    clientFirstName = "";
    clientLastName = "";
    clientSsn = "";

    attorneyFirstName = "";
    attorneyLastName = "";
    attorneySsn = "";
    companyName = "";
    vatNumber = "";

    accountId = null;

    codiceComune = ""; 
    tipologiaImmobile = "";
    sezioneCatastale = "";
    foglio = "";
    particella = "";
    subalterno = "";
    street = ""
    village = "";
    city = "";
    province = "";
    zip = "";
    region = "";

    dataConclusioneLavori;

    serramenti = [];
    

}