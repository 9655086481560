<template>
  <h3 class="display-1 font-weight-light">
    {{ message }}
  </h3>
</template>
<script>
import { verifyEmail } from "@/api/accounts.api";
export default {
  data () {
    return {
      message: "",
    };
  },
  mounted () {
    var token = this.$route.params.token;
    if (token) {
      verifyEmail({ token })
        .then(() => {
          this.$router.push("/login").catch(err=>{});
        })
        .catch(() => {
          this.message = "Token errato, reindirizzamento login...";
          setTimeout(() => {
            this.$router.push("/").catch(err=>{});
          }, 5000);
        });
    } else {
      this.message = "Token errato, reindirizzamento login...";
      setTimeout(() => {
        this.$router.push("/").catch(err=>{});
      }, 5000);
    }
  },
};
</script>