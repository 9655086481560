<template>
  <div>
    <v-card>
      <v-card-title>Pagamento</v-card-title>
          <v-row  justify="center">
          <v-col justify="center" cols="auto"  class="col-4">         
         <div class="card_container">
            <h3>PayPal</h3><br>
            <div id="paypal-button-container" class="paypal-button-container" style="width: 80% !important"></div>
          </div>
          
          </v-col>
          
          <v-col justify="center" cols="auto"  class="col-4">
          
          <div class="card_container">
            <h3>Carta di credito</h3><br>
            <form id="card-form">
              
              <v-row>
                <v-col>
                  <label for="card-number">Numero carta</label><div id="card-number" class="card_field"></div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                    <label for="expiration-date">Data di scadenza</label>
                    <div id="expiration-date" class="card_field"></div>
                </v-col>
                <v-col>
                  <label for="cvv">CVV</label><div id="cvv" class="card_field"></div>
                </v-col>
              </v-row>
              <v-row>
                  <v-col>
                    <label for="card-holder-name">Nominativo sulla carta</label>
                    <input type="text" id="card-holder-name" name="card-holder-name" autocomplete="off" placeholder="Nome e cognome"/>
                  </v-col>
              </v-row>
              <label for="card-billing-address-street">Indirizzo di fatturazione</label>
            
              <v-row>
                  <v-col class="col-10">
                      <input type="text" id="card-billing-address-street" name="card-billing-address-street" autocomplete="off" placeholder="via"/>
                  </v-col>
                  <v-col class="col-2">
                      <label for="card-billing-address-unit">&nbsp;</label>
                      <input type="text" id="card-billing-address-unit" name="card-billing-address-unit" autocomplete="off" placeholder="numero"/>
                  </v-col>
              </v-row>
              
              <v-row>
                <v-col>
                     <input type="text" id="card-billing-address-city" name="card-billing-address-city" autocomplete="off" placeholder="paese"/>
                </v-col>
                <v-col>
                     <input type="text" id="card-billing-address-state" name="card-billing-address-state" autocomplete="off" placeholder="città"/>
                </v-col>
                <v-col>
                  <input type="text" id="card-billing-address-zip" name="card-billing-address-zip" autocomplete="off" placeholder="CAP"/>
                </v-col>
              </v-row>
              <v-row> 
              <v-col>
                <button value="submit" id="submit" class="btn" width="100%" >Effettua il pagamento</button>
              </v-col>
              </v-row>
            </form>
          </div>
          </v-col>
                <v-row>
              <div class="card_container">
                <h3>Bonifico bancario</h3><br>
                <v-row>
                  <v-col>
                    Importo: <b>€ {{ parseFloat(priceWithVat).toFixed(2) }}</b>
                  </v-col>
                </v-row>           
                <v-row>
                  <v-col>
                    Intestatario: <b>Ambrosi Partner srl</b>
                  </v-col>
                </v-row> 
                <v-row>
                  <v-col>
                    IBAN: <b>IT72 V030 6934 9341 0000 0000 686</b>
                  </v-col>
                </v-row> 
                <v-row>
                  <v-col>
                   Causale Bonifico: <br><b>{{ user.companyName }} - {{ user.email }} - {{ packageName }}</b>
                  </v-col>
                </v-row>   
              </div>


            </v-row>

        </v-row> 
      </v-card>
    <v-overlay
      style="z-index:100"
      :value="loadingPayment"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>
<script>
import { getPackage, recharge } from "@/api/recharge.api";
import { generateClientID, createOrderCustom, captureOrderCustom} from "@/api/paypal.js";

import { store }  from "@/store";

import { mapGetters } from "vuex"

export default {
  data () {
    return {
      loadingPayment: false,
      currentPackage: null,
      tab: null,
      urlBase: "https://api-m.sandbox.paypal.com",
      clientID:process.env.VUE_APP_PAYPAL_CLIENT_ID ,
      clientToken:null,
      loading:false
    }
  },
  computed: {
    ...mapGetters({ user: "authentication/user" }),
    priceWithVat () {
      return this.currentPackage && this.currentPackage.priceWithVat
    },
    packageName () {
      return this.currentPackage && this.currentPackage.name
    }
  },
  created(){

  },
  methods: {
  
  },
  mounted () {
    var packageSelected = this.$route.params.type;
    
    var numCredits = 0;
    
    if(this.$route.query.numCredits != null){
      numCredits = this.$route.query.numCredits 
    }
    
    
    var self = this;
    getPackage(packageSelected,numCredits).then((res) => {
      const rPackage = res.data;
      this.currentPackage = rPackage
      var isCongruita = rPackage.creditsCongruitaSpesa;

      generateClientID().then(res=>{
        this.clientToken = res.data.client_token
        var paypalScript = document.createElement('script');
        paypalScript.setAttribute('src', 'https://www.paypal.com/sdk/js?components=buttons,hosted-fields&client-id='+this.clientID+'&currency=EUR');
        paypalScript.setAttribute('data-client-token',this.clientToken );
        paypalScript.onload =function(){
          paypal
            .Buttons({
              style: {
                layout: "horizontal",
              },
              createOrder: (data, actions) => {
                // This function sets up the details of the transaction, including the amount and line item details.
                return actions.order.create({
                  description: `${rPackage.name} - SKU:${rPackage.id}`+ (numCredits>0)?` numero asseverazioni di conguità della spesa ${numCredits}`:'',
                  purchase_units: [
                    {
                      amount: {
                        value: rPackage.priceWithVat,
                        description: `${rPackage.name} - SKU:${rPackage.id}` + (numCredits>0)?` numero asseverazioni di conguità della spesa ${numCredits}`:'',
                      },
                    },
                  ],
                });
              },
              onApprove: function (data) {
                // This function captures the funds from the transaction.
                self.loadingPayment = true
                recharge(data.orderID,numCredits, isCongruita)
                    .then(() => {
                      this.loadingPayment = false;
                    /*  user.isFirstPackageAcquired = true;
                      store.dispatch("authentication/update", this.user);
                      store.dispatch('alert/success', 'Pagamento andato a buon fine');*/
                      location.href = "#/dashboard"
                    })
                    .catch(() => {
                      self.loadingPayment = false
                      location.href = "#/errore-pagamento"
                  });               
              },
            })
            .render("#paypal-button-container");

            if (paypal.HostedFields.isEligible()) {
              let orderId;
                
              // Renders card fields
              paypal.HostedFields.render({
              // Call your server to set up the transaction
              createOrder: () => {
                return  createOrderCustom(rPackage,numCredits)
                .then(res=>{
                  orderId = res.data.id; 
                  return res.data.id;
                })
              },
                styles: {
                  ".valid": {
                    color: "green",
                  },
                  ".invalid": {
                    color: "red",
                  },
                },
                fields: {
                  number: {
                    selector: "#card-number",
                    placeholder: "4111 1111 1111 1111",
                  },
                  cvv: {
                    selector: "#cvv",
                    placeholder: "123",
                  },
                  expirationDate: {
                    selector: "#expiration-date",
                    placeholder: "MM/YY",
                  },
                },
              }).then((cardFields) => {
                document.querySelector("#card-form").addEventListener("submit", (event) => {
                  event.preventDefault();
                  self.loadingPayment = true

                  cardFields
                    .submit({
                      // Cardholder's first and last name
                      cardholderName: document.getElementById("card-holder-name").value,
                      // Billing Address
                      billingAddress: {
                        // Street address, line 1
                        streetAddress: document.getElementById(
                          "card-billing-address-street"
                        ).value,
                        // Street address, line 2 (Ex: Unit, Apartment, etc.)
                        extendedAddress: document.getElementById(
                          "card-billing-address-unit"
                        ).value,
                        // State
                        region: document.getElementById("card-billing-address-state").value,
                        // City
                        locality: document.getElementById("card-billing-address-city")
                          .value,
                        // Postal Code
                        postalCode: document.getElementById("card-billing-address-zip")
                          .value,
                        // Country Code
                        countryCodeAlpha2: "IT"
                      },
                })
                .then(() => {
                  recharge(orderId, numCredits, isCongruita)
                      .then(() => {
                        this.loadingPayment = false
                        //user.isFirstPackageAcquired = true;
                        //store.dispatch("authentication/update", this.user);
                        //store.dispatch('alert/success', 'Pagamento andato a buon fine');
                        location.href = "#/dashboard"
                      })
                      .catch(() => {
                        self.loadingPayment = false
                        location.href = "#/errore-pagamento"
                      });
            })
            .catch((err) => {
             
              self.loadingPayment = false

              if  (err.details != 'undefined' && err.details.length >0){
                let detail = err.details[0];
                if(detail.name == 'INVALID_PARAMETER_SYNTAX'){
                  store.dispatch('alert/error', 'Dati inseriti non validi, ricontrollare');
                }else if(detail.name == 'UNPROCESSABLE_ENTITY'){
                   store.dispatch('alert/error', 'Dati carta non validi, ricontrollare');
                }else{
                  store.dispatch('alert/error', 'Qualcosa è andato storto durante il pagamento, controllare i dati e riprovare');
                }
              }else{
                store.dispatch('alert/error', 'Qualcosa è andato storto durante il pagamento, controllare i dati e riprovare');
              }     
            });
        });
      });
      } else {
        // Hides card fields if the merchant isn't eligible
        document.querySelector("#card-form").style = "display: none";
      }
    }
    document.head.appendChild(paypalScript);
  });
  });
  },
};
</script>

<style scoped>
  

.paypal-button-container {
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 20px;
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
}
.col-25 {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
}
.col-50 {
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
}
.card_container {
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 20px;
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
}
.card_field{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height:40px;
    background:white;
    font-size:17px;
    color:#3a3a3a;
    font-family:helvetica, tahoma, calibri, sans-serif;
}
.card_field_50{
    width: 50%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height:40px;
    background:white;
    font-size:17px;
    color:#3a3a3a;
    font-family:helvetica, tahoma, calibri, sans-serif;
}
.card_field_75{
    width: 75%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height:40px;
    background:white;
    font-size:17px;
    color:#3a3a3a;
    font-family:helvetica, tahoma, calibri, sans-serif;
}
.row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    margin: 0 -16px;
}

input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height:40px;
    background:white;
    font-size:17px;
    color:#3a3a3a;
    font-family:helvetica, tahoma, calibri, sans-serif;
}
input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.message_container {
    border-radius: 5px;
    background:#FFFFFF;
    font-size:13px;
    font-family:monospace;
    padding: 20px;
}
#loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: block;
    opacity: 0.7;
    background-color: #fff;
    z-index: 99;
    text-align: center;
}
#loading-image {
    position: absolute;
    z-index: 15;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -150px;
}
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -50px; /* half width of the spinner gif */
    margin-top: -50px; /* half height of the spinner gif */
    text-align:center;
    z-index:1234;
    overflow: auto;
    width: 100px; /* width of the spinner gif */
    height: 102px; /* height of the spinner gif +2px to fix IE8 issue */
}
.button_container {
    display: flex;
    justify-content: center;
}
button:hover {
    background-color: powderblue;
}
button {
    width:229px;
    height:49px;
    background:lightblue;
    border:1px dotted black;
    font-size:17px;
    color:#3a3a3a;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
}
.btn_small{
    width:130px;
    height:39px;
    background:lightblue;
    border:1px dotted black;
    font-size:14px;
    color:#3a3a3a;
}
.btn_small:hover {
    background-color: powderblue;
}

#paypal-button-container {
  width: 30vw;
}
.col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  

}


#submit{
  background-color:  #61b7ba !important;
  width:100% !important;
  color: white;
}

#submit:hover{
  background-color:  #8c9eff !important;
}
</style>