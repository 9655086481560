<template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
        flat
      >
        <v-toolbar-title>Modifica pratica</v-toolbar-title>
      </v-toolbar>
      <moduleStructure :id="$route.params.id" edit-mode />
    </v-card>
  </template>
  <script>
  import moduleStructure from '@/views/modules/components/moduleStructure.vue'
  export default {
    components: { moduleStructure },
  };
  </script>
  