import {
    authService
} from '../services'
import router from '../router';
import {getCredits, getCreditsCongruitaSpesa} from '@/api/accounts.api'

const user = JSON.parse(localStorage.getItem('user'));

const init = user ? {
    status: {
        loggedIn: true
    },
    user,
    credits: 0,
    creditsCongruitaSpesa: 0
} : {
    status: {},
    credits: 0,
    creditsCongruitaSpesa: 0,
    user: null
}

export const authentication = {
    namespaced: true,
    state: init,

    actions: {
        login({dispatch, commit}, {Email, Password})
        {
            commit('loginRequest', {Email});
            //dispatch('alert/clear');
            return authService.login(Email, Password)
                .then(
                    user => {
                        if(user){
                            
                            commit('loginSuccess', user);
                            return router.push('/').catch(err=>{});
                        }
                    }
                ).catch(err=>{console.log(err)})
                
        },
        logout({
            commit
          }, redirectToLogin) {
            authService.logout();
            commit('logout');
            if (redirectToLogin) router.push('/login');
          },
        update({
            commit
        }, payload) {
            commit('update',
                payload
            )
        },
        forgotPassword({
            commit
          }, email) {
            return authService.forgotPassword(email)
          },
        resetPassword({
        commit
        }, payload) {
            return authService.resetPassword(payload)
        },
        getCredit({
            commit
          }) {
            return getCredits().then(res => {
              commit('setCredit', res.data)
            }).catch(err=>{})
      
          },
        getCreditCongruitaSpesa({
            commit
          }) {
            return getCreditsCongruitaSpesa().then(res => {
              commit('setCreditCongruitaSpesa', res.data)
            }).catch(err=>{})
      
        },
    },
    mutations: {
        loginRequest(state, user) {
            state.status = {
                loggingIn: true
            }
            state.user = user
        },
        loginSuccess(state, user) {
            state.status = {
                loggedIn: true
            };
            //user.dialogVisible = true;
            state.user = user;

        },
        loginFailure(state) {
            state.status = {}
            state.user = null
        },
        logout(state) {
            state.status = {}
            state.user = null
        },
        setCredit(state, credit) {
            state.credits = credit;
        },
        setCreditCongruitaSpesa(state, creditsCongruitaSpesa) {
            state.creditsCongruitaSpesa = creditsCongruitaSpesa;
        },
        update(state, user) {
            Object.keys(user).map((k) => {
              state.user[k] = user[k];
            });
            localStorage.setItem('user', JSON.stringify(state.user));
        }
    },
    getters: {
        user: state => {
            return state.user
        },
        credits: state => {
            return state.credits
        },
        creditsCongruitaSpesa: state => {
            return state.creditsCongruitaSpesa
        },
    }
}