import api from './baseApi'

export function uploadFile(payload, onUploadProgress) {
  return api.post(`Upload/file`, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress:event=>onUploadProgress(event)
  })
}

export function uploadFileToAzureAsseveratore(payload, onUploadProgress) {
  return api.post(`Upload/fileToAzureAsseveratore`, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: event => onUploadProgress(event)
  })
}

export function uploadFileToAzure(payload, onUploadProgress) {
  return api.post(`Upload/fileToAzure`, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: event => onUploadProgress(event)
  })
}

export function deleteFile(accountId, moduleId, fileName, ext) {
  return api.delete(`Upload/${accountId}/${moduleId}/${fileName}/${ext}`)
}

export function checkLocalFile(accountId, moduleId, fileName) {
  return api.get(`Upload/checkLocalFile/${accountId}/${moduleId}/${fileName}`)
}

export function checkAzureFile(accountId, moduleId, fileName) {
  return api.get(`Upload/checkAzureFile/${accountId}/${moduleId}/${fileName}`)
}