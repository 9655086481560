<template>
    <v-navigation-drawer
      :width="width"
      color="primary"
      permanent
      dark
      style="color:white;z-index:9999"
    >
      <v-row
        no-gutters
        align="center"
      >
        <v-col cols="10">
          <v-list-item class="pr-0">
            <v-list-item-content>
              <v-list-item-title class="title">
                Asseverazioni Bonus Barriere
              </v-list-item-title>
              <v-list-item-subtitle>
                v1.0
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="1">
          <v-btn
            rounded
            @click="closeSidebar()"
            icon
            class="close-navbar-button"
          >
            <v-icon small>
              fas fa-bars
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-list nav>
        <v-list-item
          v-for="item in getMenuItems"
          :key="item.title"
          link
          @click="item.action()"
        >
          <v-list-item-icon class="mr-2">
            <v-icon small>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
  
          <v-list-item-content>
            <v-list-item-title class="text-thin">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </template>
  <script>
  import { mapGetters } from "vuex";
  export default {
    name: "Sidebar",
    data() {
      return {
        items: []
      };
    },
    computed: {
      ...mapGetters({ sidebar: "ui/sidebar", user: "authentication/user" }),
      width() {
        return this.sidebar ? 250 : 0;
      },
      getMenuItems() {
        let items = [];
          if (this.user.role === "User") {
          items = [
            {
              icon: "fas fa-home",
              title: "Home",
              role: "",
              action: () => this.$router.push("/dashboard").catch(err=>{})
            },
  
            {
              icon: "fas fa-file-alt",
              title: "Nuova pratica",
              action: () => this.$router.push("/modules/new").catch(err=>{})
            },
            {
              icon: "fas fa-file-alt",
              title: "Pratiche in bozza",
              action: () => this.$router.push("/modules/drafts").catch(err=>{})
            },
            {
              icon: "fas fa-file-alt",
              title: "Pratiche inviate",
              action: () => this.$router.push('/modules/sended').catch(err=>{})
            },
            {
              icon: "fas fa-file-alt",
              title: "Pratiche elaborate",
              action: () => this.$router.push("/modules/elaborate").catch(err=>{})
            },
            {
              icon: "fas fa-money-bill",
              title: "Acquista servizio",
              action: () => this.$router.push("/packages").catch(err=>{})
            },
            {
              icon: "fas fa-life-ring",
              title: "Assistenza e manuale",
              action: () => this.$router.push("/help").catch(err=>{})
            },
            {
              title: "Profilo Personale",
              icon: "fas fa-user",
              action: () => this.$router.push("accounts/edit").catch(err=>{}),
            }   
                      
          ];
      
        }
     
        
      
        if (this.user.isFirstPackageAcquired) {
          items.push({
            icon: "fas fa-life-ring",
            title: "Documenti utili",
            action: () => this.$router.push("/utility-docs").catch(err=>{})
          });
        }
        
  
        if (this.user.role === "SuperAdmin" || this.user.role === "Admin") {
          items = [
            {
              icon: "fas fa-folder-open",
              title: "Pratiche da elaborare",
              action: () => this.$router.push("/administration/modules/received").catch(err=>{})
            },
            {
              icon: "fas fa-folder",
              title: "Pratiche completate",
              action: () => this.$router.push("/administration/modules/completed").catch(err => {})
            },
            {
              icon: "fas fa-user",
              title: "Gestione Utenti",
              action: () => this.$router.push("/administration/users/").catch(err=>{})
            },
           
            
            /*{
              icon: "fas fa-user",
              title: "Gestione Sponsor",
              action: () => this.$router.push("/administration/sponsors").catch(err=>{})
            }*/
          ];
        }
        if (this.user.role === "SuperAdmin") {
          items.push({
              icon: "fas fa-money-bill-alt",
              title: "Pagamenti",
              action: () => this.$router.push("/administration/payments/").catch(err=>{})
            });
          /*items.push({
            icon: "fas fa-user",
            title: "Gestione utenti admin",
            action: () => this.$router.push("/administration/users-admin/list").catch(err=>{})
          });*/
        }
        if (this.user.role === "SuperAdmin") {
          /*items.push({
            icon: "fas fa-money-bill-alt",
            title: "Pacchetti ricarica",
            action: () => this.$router.push("/packages").catch(err=>{})
          });*/
        }
       
  
      
  
        
  
        return items;
      }
    },
    mounted() {},
    methods: {
      closeSidebar() {
        this.$store.dispatch("ui/toggleSidebar");
      }
    }
  };
  
  
  </script>
  <style scoped>
  @media screen and (min-width: 1485px) {
    .close-navbar-button {
      display: none;
    }
  }
  </style>
  <style>
  .v-list .v-list-item--active {
    color:white !important;
  }
  
  </style>