
var regions = [
    "Abruzzo",
    "Basilicata",
    "Calabria",
    "Campania",
    "Emilia-Romagna",
    "Friuli Venezia Giulia",
    "Lazio",
    "Liguria",
    "Lombardia",
    "Marche",
    "Molise",
    "Piemonte",
    "Puglia",
    "San Marino",
    "Sardegna",
    "Sicilia",
    "Toscana",
    "Trentino",
    "Alto Adige",
    "Umbria",
    "Valle d'Aosta",
    "Veneto",
    
  ]


export default regions;