<template>
   
    <v-row>
        <!--<v-dialog
            v-model="user.dialogVisible"
            persistent
        >
      <v-card
        color="primary"
        dark
      >
        <v-card-title class="headline">
            Comunicazione chiusura aziendale
        </v-card-title>
        <v-card-text>
            Gentile cliente, la informiamo che l'Accademia Ambrosi Partner chiuderà per il periodo natalizio dal 23 dicembre al 7 gennaio, pertanto il processamento delle pratiche e l'erogazione dei servizi acquistati avverrà con tempistiche più lunghe. Per avere disponibilità immediata dei crediti si consiglia l'acquisto tramite PayPal o Carta di credito. L'Accademia riprenderà la regolare attività a partire dall'8 gennaio.<br>
            <b>Cogliamo l'occasione per augurare buone feste da parte di tutto il team Ambrosi Partner!</b>
        </v-card-text>
        <v-card-actions>
                <v-spacer />
                <v-btn @click="closeDialog()" color="primary"> Chiudi </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
        <v-col
            cols="12"
            xs="12"
            md="3"
            >
            <v-card
                dark
                color="light-blue darken-1"
                @click="$router.push('/modules/drafts').catch(err=>{})"
            >
                <v-card-title>Pratiche Caricate</v-card-title>
                <v-card-text class="headline">
                    {{ uploadedModules }}
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="3"
        >
            <v-card
                dark
                color="teal darken-1"
                @click="$router.push('/modules/sended').catch(err=>{})"
            >
                <v-card-title>Pratiche Inviate</v-card-title>
                <v-card-text class="headline">
                    {{ sentModules }}
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="3"
        >
            <v-card
                dark
                color="#E1C699"
                @click="$router.push('/packages').catch(err=>{})"
            >
                <v-card-title>Asseverazioni barriere</v-card-title>
                <v-card-text class="headline">
                  {{ credits }}
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="3"
        >
            <v-card
                dark
                color="#E1C699"
                @click="$router.push('/packages').catch(err=>{})"
            >
                <v-card-title>Asseverazioni spesa</v-card-title>
                <v-card-text class="headline">
                    {{ creditsCongruitaSpesa }}
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="3"
        >
            <v-card
                dark
                color="indigo darken-1"
                @click="$router.push('/packages').catch(err=>{})"
                height="100%"
            >
                <v-card-title>Acquista Servizio</v-card-title>
                <v-card-text class="headline">
                ‎‎‎‎
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="9"
        >
        <a href="https://ambrosipartner.com/negozio" target="_blank" style="text-decoration: none;">
            <v-card
                dark
                color="#C21923"
            >
                <v-card-title>Attenzione</v-card-title>
                <v-card-text class="headline">
                        Per richiedere l'asseverazione di congruità della spesa è necessario che il documento per asseveratore 
                        sia creato utilizzando il modulo aggiuntivo di ottimizzazione del programma fattura infissi acquistabile nel negozio del sito <span style="text-decoration:underline">https://ambrosipartner.com/negozio</span>
                </v-card-text>
            </v-card>
        </a>
        </v-col>
        
        <v-col
            cols="12"
            xs="12"
            md="6"
        >
         <v-card
                dark
                color="primary"
                height="100%"
            >
                <v-card-title>IN CASO DI DETRAZIONE DIRETTA</v-card-title>
                <v-card-text class="headline">
                    va caricata solo una pratica di conformità ai requisiti del  D.M. 236/89. La bozza va creata già prima della conferma d’ordine perché così puoi scaricare subito i documenti per la fase preventiva da far firmare al cliente. Al termine dei lavori andranno caricate le dichiarazioni del serramentista + le foto dei lavori ultimati                                                                         
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="6"
        >
         <v-card
                dark
                color="primary"
            >
                <v-card-title>IN CASO DI SCONTO IN FATTURA</v-card-title>
                <v-card-text class="headline">
                    va caricata prima la pratica di asseverazione di congruità della spesa e poi quella di conformità ai requisiti del D.M.236/89. La bozza della pratica di congruità va creata già prima della conferma d’ordine perché così è possibile scaricare eventuali documenti per la fase preventiva. Al termine dei lavori andrà creata la pratica di confromità al D.M. 236/89 e quindi andranno caricate le dichiarazioni del serramentista + le foto dei lavori ultimati
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="6"
            >
            <v-card
                dark
                color="light-blue darken-1"
                @click="openInNewTab('/assets/Procedura asseverazione DM236.pdf')"
            >
                <v-card-title>PROCEDURA SCONTO IN FATTURA/DETR. DIRETTA DM 236/1989</v-card-title>
                <v-card-text class="headline">
                    Download
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="6"
            >
            <v-card
                dark
                color="light-blue darken-1"
                @click="openInNewTab('/assets/Procedura asseverazione - vers. Deloitte.pdf')"
            >
                <v-card-title>PROCEDURA SCONTO IN FATTURA/CESSIONE DELOITTE/XBONUS</v-card-title>
                <v-card-text class="headline">
                    Download
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    import { mapGetters } from "vuex";
    import {getCountModules} from "@/api/module.api.js"
    export default {
        name: "Dashboard",
        data () {
            
        return {
            uploadedModules:0,
            sentModules:0,
            //dialogVisible: true
        };
    },
    computed: {
        ...mapGetters({
            user: "authentication/user",
            credits: "authentication/credits",
            creditsCongruitaSpesa: "authentication/creditsCongruitaSpesa",
        }),
    },
    mounted () {    
        this.$store.dispatch("authentication/getCredit");
        this.$store.dispatch("authentication/getCreditCongruitaSpesa");
        
      
        getCountModules().then(res=>{
            this.uploadedModules = res.data.drafts;
            this.sentModules = res.data.sent;
        }).catch(err=>{
            console.log(err);
        });



    },
    methods: {
        openInNewTab (href) {
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: href,
            }).click();
        },
        closeDialog(){
            //this.user.dialogVisible = false;
            this.$store.dispatch("authentication/update", this.user);
        }
    }
}
</script>