<template>
    <v-toolbar>
      <v-app-bar-nav-icon @click="toggleSidebar()" />
      <v-toolbar-title>Asseverazioni Bonus Barriere</v-toolbar-title>
      <v-spacer />
      <div v-if = "false">
      <!--<span
        v-if="!isAdmin&&!isBuyer"
        class="mr-2"
      >
      Pratiche disponibili:<b>{{ creditsPostDLAntifrodi }}</b>
        <v-btn
          rounded
          color="primary"
          class="mr-5"
          text
          @click="$router.push('ricarica')"
        > <b>{{ credits }} &nbsp; </b> ( Cessione: {{ creditCessione?creditCessione:0 }} )</v-btn>
      </span>-->
      </div>
      <v-menu
        v-if="!isAdmin&&!isBuyer"
        offset-y
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="notificationCount"
            overlap
            :class="notifications.length>0?'':'hide-badge'"
            class="custom-badge"
          >
            <v-btn
              rounded
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>fas fa-bell</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <v-list v-if="notifications.length>0">
          <v-list-item
            v-for="(item, index) in notifications"
            :key="index"
            two-line
            dense
            width="300"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                text
                small
                @click="readNotification(item.id)"
              >
                <v-icon small>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
  
      <v-menu
        offset-y
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            small
            v-bind="attrs"
            v-on="on"
            class="ml-10 mr-2"
          >
            <v-avatar
              color="primary"
              size="40"
            >
              <span class="white--text title">{{ userInitials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in userMenu"
            :key="index"
            two-line
            @click="item.action()"
          >
            <v-list-item-title>
              <v-icon class="mr-2">
                {{ item.icon }}
              </v-icon> {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </template>
  <script>
  import { mapGetters } from "vuex";
  //import { getNotifications, readNotification } from "@/api/usermanagement.api"
  export default {
    name: "Toolbar",
    data () {
      return {
        notificationCount: 0,
        notifications: [],
        interval: null
      };
    },
    mounted () {
      /*this.$store.dispatch("authentication/getCredit");
      this.$store.dispatch("authentication/getCreditCessione");
      this.$store.dispatch("authentication/getCreditPostDLAntifrodi");
      this.$store.dispatch("authentication/getCreditAsseverazione");
      this.$store.dispatch("authentication/getOpportunitiesAvailable");
      this.$store.dispatch("authentication/getOpportunitiesFreezed");*/
  
    },
    computed: {
      ...mapGetters({
        user: "authentication/user",
        /*credits: "authentication/credits",
        creditsPostDLAntifrodi: "authentication/creditsPostDLAntifrodi", 
        creditCessione: "authentication/creditCessione",*/
  
      }),
      userMenu () {
        let baseMenu = [{ text: "Logout", icon: "fas fa-sign-out-alt", action: this.logout },]
        if (!this.isAdmin && !this.isBuyer) {
          baseMenu.push(
            {
              text: "Profilo",
              icon: "fas fa-user",
              action: () => this.$router.push("/accounts/edit").catch(err=>{}),
            })
        }
        return baseMenu
      },
      userInitials () {
        if (this.user.firstName && this.user.lastName) {
          return this.user.firstName[0] + this.user.lastName[0];
        }
        else {
          return this.user.firstName[0]
        }
      },
      isAdmin () {
        return this.user.role === "SuperAdmin" || this.user.role === "Admin"
      },
      isBuyer () {
        return this.user.role === "Buyer"
      }
    },
    created () {
      this.interval = setInterval(this.fetchNotifications, 240000);
    },
    beforeDestroy () {
      clearInterval(this.interval);
    },
    methods: {
      toggleSidebar () {
        this.$store.dispatch("ui/toggleSidebar");
      },
      logout () {
        this.$store.dispatch("authentication/logout", true);
      },
      fetchNotifications () {
        /*getNotifications().then(res => {
          this.notificationCount = res.data.count;
          this.notifications = res.data.notifications;
        })*/
      },
      readNotification (id) {
        /*readNotification(id).then(res => {
          this.fetchNotifications();
        })*/
      }
    },
  };
  </script>
  <style>
  .hide-badge .v-badge__badge {
    display: none;
  }
  </style>