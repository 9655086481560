import axios from 'axios';
import {Buffer} from 'buffer';
 

export function generateAccessToken() {
    const auth = Buffer.from(process.env.VUE_APP_PAYPAL_CLIENT_ID + ":" + process.env.VUE_APP_PAYPAL_APP_SECRET).toString("base64");
    let paypalService = axios.create({
        baseURL: process.env.VUE_APP_PAYPAL_URL,
    });
    return paypalService.request({
        method: 'POST',
        url: '/v1/oauth2/token',
        responseType: 'json',
        data: "grant_type=client_credentials",
        headers: {
            Authorization: `Basic ${auth}`,
        }
      });   
}


export function generateClientID() {
       return generateAccessToken()
        .then(res=>{
            return res.data.access_token;
        })
        .then(accessToken=>{
            let paypalService = axios.create({
                baseURL: process.env.VUE_APP_PAYPAL_URL,
            });
            return paypalService.request({
                method: 'POST',
                url: '/v1/identity/generate-token',
                responseType: 'json',
                data: null,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Accept-Language": "it_IT",
                    "Content-Type": "application/json",
                  }
            })
        })
        .catch(err=>{

        });
  }


  export async function createOrderCustom(rPackage, numCreditsCongruitaSpesa) {
    
    const accessToken = await generateAccessToken();

    let paypalService = axios.create({
        baseURL: process.env.VUE_APP_PAYPAL_URL,
    });
    return paypalService.request({
        method: 'POST',
        url: '/v2/checkout/orders',
        responseType: 'json',
        data: {
            intent: "CAPTURE",
            purchase_units: [
              {
                amount: {
                  value: rPackage.priceWithVat,
                  currency_code: "EUR",
                  description: `${rPackage.name} - SKU:${rPackage.id}`+(numCreditsCongruitaSpesa>0)?` numero asseverazioni di conguità della spesa ${numCreditsCongruitaSpesa}`:'',
                },
              },
            ],
        },
        headers: {
            Authorization: `Bearer ${accessToken.data.access_token}`,
            "Accept-Language": "it_IT",
            "Content-Type": "application/json",
          }
    })
    .catch(err=>{
        console.log(err);
    })  
}


export async function captureOrderCustom(orderId) {
    
    const accessToken = await generateAccessToken();
    let paypalService = axios.create({
        baseURL: process.env.VUE_APP_PAYPAL_URL,
    });
    return paypalService.request({
        method: 'POST',
        url: `/v2/checkout/orders/${orderId}/capture`,
        responseType: 'json',
        data: null,
        headers: {
            Authorization: `Bearer ${accessToken.data.access_token}`,
            "Accept-Language": "it_IT",
            "Content-Type": "application/json",
          }
    })
    .catch(err=>{
        console.log(err);
    })  
}
