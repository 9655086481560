<template>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :value="formattedValue"
          :label="label"
          :rules="rules"
          readonly
          placeholder=" "
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        ref="picker"
        v-model="date"
        :min="minDate"
        :max="maxDate"
      />
    </v-menu>
  </template>
  <script>
  export default{
      name: 'DatePicker',
      props: {
          value: {
              type: String,
              default:null
          },
          label: {
              type: String,
              required: true
          },
          minDate: {
              type: String,
              default:null
          },
          maxDate: {
              type: String,
              default:null
          },
          rules: {
              type: Array,
              default:()=>([])
          }
      },
      data() {
          return {
              menu:false,
              date:''
          }
      },
      computed: {
          formattedValue () {
              const formattedValue = this.date ? this.date.split('-').reverse().join('/') : ''
              this.$emit('input', formattedValue)
              return formattedValue
          },
      },
      mounted () {
        const formattedValue = this.value ? this.value.split('/').reverse().join('-') : ''
        this.date = formattedValue
      }
  }
  </script>