<template>
    <v-row
      no-gutters
      class="background"
    >
      <v-col
        cols="auto"
        class="custom-sidebar"
      >
        <sidebar />
      </v-col>
      <v-col class="custom-container">
        <toolbar />
        <v-main>
          <v-container fluid>
            <router-view />
          </v-container>
        </v-main>
      </v-col>
    </v-row>
  </template>
  <script>
  import toolbar from "../../components/toolbar";
  import sidebar from "../../components/sidebar";
  export default {
    components: { toolbar, sidebar },
  };
  </script>
  <style scoped>
  @media screen and (max-width: 1483px) {
    .custom-sidebar {
      position: absolute;
      flex: none;
      z-index: 2;
      height: 100%;
    }
  }
  .custom-container {
    z-index: 1;
  }
  .background {
    background-image: url("@/assets/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /*max-width: unset !important;
    max-height: unset !important;
    */width: 100vw;
    height: 100vh;
  }
  </style>