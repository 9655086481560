import Vue from 'vue';
import Vuex from 'vuex';
import VueSimpleAlert from "vue-simple-alert";


import { alert } from './alert.module';
import { authentication } from './auth.module';
//import { users } from './users.module';
import { ui } from './ui.module';
import { municipalities } from './municipalities.module';
import { moduleStatuses } from './statuses.module';

Vue.use(Vuex);
Vue.use(VueSimpleAlert);

export const store = new Vuex.Store({
    modules: {
        authentication,
        municipalities,
        moduleStatuses,
        ui,
        alert
    }
});